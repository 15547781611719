import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Vendor } from 'src/app/core/models/vendor.model';
import { VendorService } from 'src/app/core/services/vendor.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss'],
})
export class VendorComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() vendorField: ExpenseField;

  @Input() vendor: Vendor | string | null;

  filteredOptions: string[];

  filteredVendors: Vendor[];

  constructor(private vendorService: VendorService) {}

  ngOnInit() {}

  filterVendors(event: AutoCompleteCompleteEvent) {
    if (event.query?.length === 0) {
      this.filteredVendors = [];
      return;
    }
    this.vendorService.getList(event.query).subscribe((vendors) => {
      this.filteredVendors = vendors;
    });
  }

  filterOptions(event: AutoCompleteCompleteEvent) {
    this.filteredOptions = this.vendorField.options.filter((option) =>
      option.toLowerCase().includes(event.query.toLowerCase())
    );
  }

  onInputKeyUp(event: Event, autoComplete: AutoComplete) {
    if (autoComplete.inputEL.nativeElement.value === '') {
      this.filterVendors({ originalEvent: event, query: '' });
      autoComplete.show();
    }
  }
}
