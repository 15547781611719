import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-violated-action',
  templateUrl: './policy-violated-action.component.html',
  styleUrls: ['./policy-violated-action.component.scss'],
})
export class PolicyViolatedActionComponent implements OnInit {
  @Input() actionText: string;

  @Input() icon: string;

  @Input() emails: string[];

  constructor() {}

  ngOnInit(): void {}
}
