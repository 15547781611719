<div class="date relative" [formGroup]="expenseForm">
  <div class="date-container common-input flex" [ngClass]="{'text-error': expenseForm.controls[inputId].touched && expenseForm.controls[inputId].invalid}">
    <label for="{{inputId}}">
      {{ dateField.field_name }} 
      @if (dateField.is_mandatory) {
        <span>*</span>
      }
    </label>
    <div class="value">
      <p-calendar #calendar
        [defaultDate]="defaultDate"
        inputStyleClass="font-600 text-black border-0 shadow-none"
        formControlName="{{inputId}}"
        dateFormat="M dd, yy"
        id="p-{{inputId}}"
        inputId="{{inputId}}"
        [maxDate]="maxDate"
        placeholder="{{ dateField.placeholder }}">
      </p-calendar>
      <svg-icon-sprite class="icon-feature" src="calendar" (click)="openCalendar(calendar)"></svg-icon-sprite>
    </div>
  </div>
  @if (expenseForm.controls[inputId].touched && expenseForm.controls[inputId].invalid) {
    <span class="text-error ">Please select {{ dateField.field_name }}.</span>
  }
</div>
