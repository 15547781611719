<div class="tax-group" [formGroup]="expenseForm">
  <div class="tax-selection common-input" [ngClass]="{'text-error': expenseForm.controls.taxGroup.touched && expenseForm.controls.taxGroup.invalid}">
    <label for="fyle-tax-group" >
      {{ taxGroupField.field_name || 'Tax' }}
      @if (taxGroupField.is_mandatory) {
        <span>*</span>
      }
    </label>
    <div class="value">
      <app-autocomplete
        formControlName="taxGroup"
        inputId="taxGroup"
        fieldName="name"
        type="text"
        [options]="taxGroups"
        [placeholder]="taxGroupField.placeholder || 'Tax'"
        [isDropdown]="true">
      </app-autocomplete>
    </div>

  </div>

  @if (expenseForm.controls.taxGroup.touched && expenseForm.controls.taxGroup.invalid) {
    <span class="text-error">Please select a {{ taxGroupField.field_name || 'Tax' }}.</span>
  }

  <div class="tax-amount common-input">
    <label for="fyle-tax" [textContent]="taxLabel || 'Tax Amount'"></label>
    <div class="value">
      <p-inputNumber
        inputStyleClass="text-black font-600 text-12 w-full border-0"
        autocomplete="off"
        formControlName="taxAmount"
        mode="decimal"
        id="p-tax-amount"
        inputId="taxAmount"
        placeholder="tax amount"
        [useGrouping]="false">
      </p-inputNumber>
    </div>
  </div>

</div>