import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';

@Component({
  selector: 'app-travel-class',
  templateUrl: './travel-class.component.html',
  styleUrls: ['./travel-class.component.scss'],
})
export class TravelClassComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() travelClassField: ExpenseField;

  @Input() inputId: string;

  @Input() inputName: string;

  constructor() {}

  ngOnInit() {}
}
