import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ExtensionEventService } from '@core/services/extension-event.service';
import { LaunchDarklyService } from '@core/services/launch-darkly.service';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { globalCacheBusterNotifier } from 'ts-cacheable';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private window: Window,
    private authService: AuthService,
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private extensionEventService: ExtensionEventService
  ) {}

  ngOnInit() {}

  logout() {
    this.authService
      .logout()
      .pipe(
        finalize(() => {
          globalCacheBusterNotifier.next();
          this.openLogoutWindow();
          this.extensionEventService.postMessageToExtension({ logoutFyleUser: true });

          this.launchDarklyService.shutDownClient();
        })
      )
      .subscribe();
  }

  private openLogoutWindow() {
    const uri = `${environment.APP_URL}/accounts/#/logout?asset=chrome`;
    this.window.open(uri, '_blank');
    setTimeout(() => {
      this.goToLoginPage();
    }, 1000);
  }

  private goToLoginPage() {
    this.router.navigate(['auth/login']);
  }
}
