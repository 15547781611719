export const environment = {
    production: true,
    ROOT_URL: '',
    APP_URL: 'https://app.fylehq.com/app',
    SENTRY_DSN_EXTENSION: 'https://0a12f7e4a99d465396604e2a3e6dbf77@o341960.ingest.sentry.io/5790023',
    ENABLE_SENTRY_TRACING: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.3,
    ENVIRONMENT: 'production',
    EXTENSION_NETLIFY_URL: 'https://extension.fylehq.com',
    RELEASE: '442b0d9383e5931906f3719ad9a50972f82b9605',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe55',
    MIXPANEL_PROJECT_TOKEN: '56c5f4864cd63c40ee61dc476457f693',
    USE_MIXPANEL_PROXY: 'true',
    ENABLE_MIXPANEL: 'true',
    ROUTER_API_ENDPOINT: 'https://accounts.fylehq.com',
  };