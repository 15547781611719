import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { findLastIndex } from 'lodash';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
  AutoCompleteUnselectEvent,
} from 'primeng/autocomplete';
import { noop } from 'rxjs';

/**
 * How to use the component?
 * Assume you have the following options
  this.options = [
      {
        'name': 'Aditya',
        'age': 28,
        isRecentlyUsed: true
      },
      {
        'name': 'Dhar',
        'age': 28,
        isFirstInOption: true
      },
      {
        'name': 'Sravan',
        'age': 28
      },
      {
        'name': 'Dimple',
        'age': 28
      },
      {
        'name': 'Nilesh',
        'age': 28
      }
    ]
 *  <app-autocomplete
      formControlName="user"
      [options]="options"
      [inputId]="'name'"
      [fieldName]="'name'">
    </app-autocomplete>
 */

@Component({
  selector: 'app-autocomplete',
  templateUrl: './fy-autocomplete.component.html',
  styleUrls: ['./fy-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FyAutocompleteComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FyAutocompleteComponent,
      multi: true,
    },
  ],
})
export class FyAutocompleteComponent implements OnInit, ControlValueAccessor, Validator {
  // options can be array of string or array of object.
  @Input() options: any[];

  // fieldname that needs to be displayed in the UI if options is an array of object.
  @Input() fieldName: string;

  @Input() isDropdown: boolean;

  @Input() inputId: string;

  @Input() type?: string;

  // style classes to be applied to `input` element.
  @Input() inputStyleClass?: string;

  @Input() panelStyleClass?: string;

  @Input() styleClass?: string;

  @Input() placeholder?: string;

  // allow multiple selection from `options` list.
  @Input() multiple = false;

  // If passed as true, marks the form as invalid if the entered value is not present in the `options` list.
  @Input() forceSelection = true;

  @Output() selectedOption: EventEmitter<any> = new EventEmitter();

  @Output() unSelectedOption: EventEmitter<any> = new EventEmitter();

  filteredOptions: any[];

  value: any;

  disabled = false;

  currentSelectedOption = '';

  private onModelTouched: () => void = noop;

  private onModelChange: (_: any) => void = noop;

  private touched = false;

  constructor() {}

  ngOnInit() {}

  validate(control: AbstractControl): ValidationErrors | null {
    const isValidValue = this.forceSelection && this.isValidValue();
    return isValidValue ? null : { invalidValue: true };
  }

  filterOptions(event: AutoCompleteCompleteEvent) {
    if (this.fieldName) {
      const filteredOptions = this.options.filter((option) =>
        option[this.fieldName].toLowerCase().includes(event.query.toLowerCase())
      );
      if (filteredOptions.length > 0) {
        filteredOptions.forEach((res) => {
          delete res.isFirstInOption;
        });
        // find the last index of the option where isRecentlyUsed is true.
        const lastRecentlyUsedItemIdx = findLastIndex(filteredOptions, { isRecentlyUsed: true });
        // `isFirstInOption` is added to the next option to apply the divider.
        if (lastRecentlyUsedItemIdx > -1) {
          filteredOptions[lastRecentlyUsedItemIdx + 1].isFirstInOption = true;
        }
      }
      this.filteredOptions = filteredOptions;
    } else {
      this.filteredOptions = this.options.filter((option) => option.toLowerCase().includes(event.query.toLowerCase()));
    }
    if (!this.multiple && event.query) {
      this.onModelChange(event.query);
    }
  }

  onOptionSelection(event: AutoCompleteSelectEvent) {
    if (this.multiple) {
      const optionIndex = this.options.findIndex((val) => val === event);
      if (optionIndex > -1) {
        this.options.splice(optionIndex, 1);
      }
    }
    if (!this.multiple) {
      if (typeof event === 'string') {
        this.currentSelectedOption = event;
      } else {
        if (event.value.displayValue) {
          this.currentSelectedOption = event.value.displayValue;
        } else if (event.value.display_name) {
          this.currentSelectedOption = event.value.display_name;
        } else {
          this.currentSelectedOption = event.value.name;
        }
      }
    }
    this.onModelChange(this.value);
    this.selectedOption.emit(event);
  }

  onOptionDeSelection(event: AutoCompleteUnselectEvent) {
    if (this.multiple) {
      this.options.push(event.value);
    }
    this.onModelChange(this.value);
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onModelTouched = fn;
  }

  setDisabledState(value: boolean) {
    this.disabled = value;
  }

  onBlur() {
    if (!this.touched) {
      this.touched = true;
      this.onModelTouched();
    }
    this.updateValue();
  }

  onInputKeyUp(autoComplete: AutoComplete) {
    this.currentSelectedOption = '';
    if (autoComplete.inputEL.nativeElement.value === '') {
      this.filteredOptions = this.options;
      autoComplete.show();
    }
  }

  private updateValue() {
    /**
     * If the value is matched from the `options` ignoring the case, updating the control value too.
     * Do not do update the model value inside the `isValidValue` method, since `onModelChange` triggers the `validate`
     * method and it goes into infinite loop.
     */

    this.onModelChange(this.value);
  }

  private isValidValue(): boolean {
    let isValidValue = false;

    if (!this.value) {
      return true;
    }

    if (this.fieldName) {
      // when the user types instead of selecting from the suggestions, `this.value` will be of type string.
      const isValueString = typeof this.value === 'string';
      const option = isValueString
        ? this.options.find((val) => this.value?.toLowerCase() === val[this.fieldName].toLowerCase())
        : this.options.find(
          (val) => this.value && this.value[this.fieldName]?.toLowerCase() === val[this.fieldName].toLowerCase()
        );

      if (option) {
        this.value = option;
        isValidValue = true;
      }
    } else {
      if (this.multiple) {
        // for multi select `forceSelection` is true and handled by the primeng, hence not validating it.
        isValidValue = true;
      } else {
        isValidValue = this.options?.some((val) => this.value?.toLowerCase() === val.toLowerCase());
      }
    }
    return isValidValue;
  }
}
