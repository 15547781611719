import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() inputId: string;

  @Input() dateField: ExpenseField;

  @Input() maxDate: Date;

  defaultDate: Date = new Date();

  constructor() {}

  ngOnInit() {
    if (!this.inputId) {
      this.inputId = this.dateField.field_name;
    }
    if (!this.maxDate) {
      this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    }
  }

  // @link https://stackoverflow.com/questions/44011199/open-primeng-calendar-programmatically
  openCalendar(calendar: Calendar) {
    if (!calendar.overlayVisible) {
      calendar.inputfieldViewChild.nativeElement.focus();
      calendar.showOverlay();
    } else {
      calendar.inputfieldViewChild.nativeElement.blur();
      calendar.hideOverlay();
    }
  }
}
