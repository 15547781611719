import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReportService } from '@core/services/platform/v1/spender/report.service';
import { orderBy } from 'lodash';
import { Report } from '@core/models/platform/v1/spender/report.model';
import { AddToReportOptions } from './add-to-report.model';

@Component({
  selector: 'app-add-to-report',
  templateUrl: './add-to-report.component.html',
  styleUrls: ['./add-to-report.component.scss'],
})
export class AddToReportComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() reports: Report[];

  formattedReports: AddToReportOptions[];

  constructor(private reportService: ReportService) {}

  ngOnInit() {
    this.reports = orderBy(this.reports, (item) => item.state, 'desc');
    this.formattedReports = this.reportService.getFormattedReports(this.reports) as AddToReportOptions[];
  }
}
