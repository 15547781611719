import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { CustomFields } from '@core/models/custom-field.model';
import { isEqual } from 'lodash';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { EmployeeService } from '@core/services/platform/v1/spender/employee.service';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit, OnChanges {
  @Input() expenseForm: UntypedFormGroup;

  @Input() customFields: CustomFields[];

  @Input() userIdToExclude: string;

  customInputForm: UntypedFormGroup[];

  employees: string[];

  defaultDate: Date = new Date();

  userListQuery: Record<string, string> = {};

  constructor(private employeeListService: EmployeeService) {}

  ngOnInit() {
    const customInputForm = this.expenseForm.controls.customInputs as UntypedFormArray;
    this.customInputForm = customInputForm.controls as UntypedFormGroup[];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.customFields.currentValue &&
      changes.customFields.previousValue &&
      !isEqual(changes.customFields.currentValue, changes.customFields.previousValue)
    ) {
      const customInputForm = this.expenseForm.controls.customInputs as UntypedFormArray;
      this.customInputForm = customInputForm.controls as UntypedFormGroup[];
    }
  }

  // @link https://stackoverflow.com/questions/44011199/open-primeng-calendar-programmatically
  openCalendar(calendar: Calendar) {
    if (!calendar.overlayVisible) {
      calendar.inputfieldViewChild.nativeElement.focus();
      calendar.showOverlay();
    } else {
      calendar.inputfieldViewChild.nativeElement.blur();
      calendar.hideOverlay();
    }
  }

  searchEmployees(event: AutoCompleteCompleteEvent, customField: CustomFields, index: number) {
    const query = event.query || '';
    this.employeeListService.getEmployeesByFullnameOrEmail(query).subscribe((employees) => {
      employees = employees.filter((employee) => employee.user_id !== this.userIdToExclude);
      const employeesEmail = employees.map((employee) => employee.email);
      const currentValue = this.customInputForm[index].controls[customField.name].value || [];
      this.employees = employeesEmail.filter((employeeEmail) => !currentValue.includes(employeeEmail));
      if (query.length > 0) {
        this.userListQuery[customField.name] = query;
        this.employees = [query].concat(this.employees);
      }
    });
  }

  onInputKeyUp(event: Event, customField: CustomFields, autoComplete: AutoComplete, index: number) {
    if (autoComplete.inputEL.nativeElement.value === '') {
      this.userListQuery[customField.name] = '';
      this.searchEmployees({ originalEvent: event, query: '' }, customField, 0);
      autoComplete.show();
    }
  }

  onSelect(customField: CustomFields, autoComplete: AutoComplete) {
    if (this.userListQuery[customField.name]) {
      this.userListQuery[customField.name] = '';
    }
    if (autoComplete.inputEL.nativeElement.value === '') {
      autoComplete.show();
    }
  }
}
