import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isNumber } from 'lodash';
import { Currency } from '@core/models/platform/v1/common/currency.model';
import { CurrencyObject, CurrencyOptions } from '../currency/currency.interface';

@Component({
  selector: 'app-expense-amount',
  templateUrl: './expense-amount.component.html',
  styleUrls: ['./expense-amount.component.scss'],
})
export class ExpenseAmountComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() currencyObj: CurrencyObject;

  @Input() currencies: Currency[];

  @Input() options?: CurrencyOptions;

  @Input() isCorporateCardEnabled?: boolean;

  @Input() recentlyUsedCurrencies?: string[];

  constructor() {}

  ngOnInit() {}

  isAmountANumber(amount: number | undefined) {
    return isNumber(amount);
  }
}
