import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';

@Component({
  selector: 'app-distance',
  templateUrl: './distance.component.html',
  styleUrls: ['./distance.component.scss'],
})
export class DistanceComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() distanceField: ExpenseField;

  @Input() distanceUnitField: ExpenseField;

  constructor() {}

  ngOnInit() {}
}
