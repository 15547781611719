@if (expenseForm.controls[inputName]) {
  <div class="travel-class" [formGroup]="expenseForm">
    <div class="common-input" [ngClass]="{'text-error': expenseForm.controls[inputName].touched  && expenseForm.controls[inputName].invalid}">
      <label for="{{inputId}}">{{ travelClassField.field_name }} <span>*</span></label>
      <div class="value">
        @if (travelClassField.type === 'TEXT') {
          <input
            pInputText
            autocomplete="off"
            id="{{inputId}}"
            type="text"
            class="text-black font-600 w-full text-12 border-0"
            formControlName="{{inputName}}"
            placeholder="{{ travelClassField.placeholder }}">
        }
        @if (travelClassField.type === 'SELECT') {
          <app-autocomplete #autoComplete
            autocomplete="off"
            formControlName="{{inputName}}"
            id="p-{{inputId}}"
            inputId="{{inputName}}"
            type="text"
            placeholder="{{ travelClassField.placeholder }}"
            [options]="travelClassField.options"
            [isDropdown]="true">
          </app-autocomplete>
        }
      </div>
    </div>
    @if (expenseForm.controls[inputName].touched && expenseForm.controls[inputName].invalid) {
      @if (travelClassField.type === 'SELECT') {
        <span class="text-error">Please select a {{ travelClassField.field_name }}.</span>
      }
      @if (travelClassField.type === 'TEXT') {
        <span class="text-error"> Please enter {{ travelClassField.field_name }}.</span>
      }
    }
  </div>
}