<div class="flight-travel-class" [formGroup]="expenseForm">
  <div class="common-input" [ngClass]="{'text-error': expenseForm.controls[inputId].touched && expenseForm.controls[inputId].invalid}">
    <label for="{{inputId}}">{{ travelClassField.field_name }} <span>*</span></label>
    <div class="value">
      <app-autocomplete
        #autoComplete
        [inputId]="inputId"
        formControlName="{{inputId}}"
        [placeholder]="travelClassField.placeholder"
        [options]="travelClassField.options"
        [isDropdown]="true">
      </app-autocomplete>
    </div>
  </div>
  @if (expenseForm.controls[inputId].touched && expenseForm.controls[inputId].invalid) {
    <span class="text-error">Please select a {{ travelClassField.field_name }}.</span>
  }
</div>