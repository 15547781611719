import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MailFileDetails } from 'src/app/models/mail-details.interface';

@Component({
  selector: 'app-pdf-password',
  templateUrl: './pdf-password.component.html',
  styleUrls: ['./pdf-password.component.scss'],
})
export class PdfPasswordComponent implements OnInit {
  @ViewChild('vendorPasswordForm') vendorPasswordForm: NgForm;

  @Input() encryptedFiles: MailFileDetails[];

  @Output() checkDecryption: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onCheckDecryption() {
    this.vendorPasswordForm.form.markAsTouched();
    this.checkDecryption.emit(true);
  }
}
