import { TitleCasePipe, SlicePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { isArray } from 'lodash';
import { Observable, from, of } from 'rxjs';
import { map, mergeMap, reduce, switchMap } from 'rxjs/operators';
import { AddToReportOptions } from 'src/app/shared/components/add-to-report/add-to-report.model';
import { StatePipe } from 'src/app/shared/pipes/state.pipe';
import { Cacheable } from 'ts-cacheable';
import { PlatformV1SpenderService } from './spender.service';
import { ReportQueryParams, Report, ReportSatus } from '@core/models/platform/v1/spender/report.model';
import { ApiResponse } from '@core/models/platform/v1/spender/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private platformV1SpenderService: PlatformV1SpenderService) {}

  @Cacheable()
  getAllReportsByParams(queryParams: ReportQueryParams = {}): Observable<Report[]> {
    return of(queryParams).pipe(
      // first we will get count of categories and on basis of that make parallel calls
      mergeMap((queryParams) => this.getReportsCount(queryParams)),
      mergeMap((count) => this.getReportsInParallel(count, queryParams))
    );
  }

  getReportsByParams(
    queryParams: ReportQueryParams = {},
    offset: number = 0,
    limit: number = 1
  ): Observable<ApiResponse<Report[]>> {
    const config = {
      params: {
        ...queryParams,
        offset,
        limit,
      },
    };
    return this.platformV1SpenderService.get<ApiResponse<Report[]>>('/reports', config);
  }

  getReportsCount(queryParams?: ReportQueryParams): Observable<number> {
    return this.getReportsByParams(queryParams, 0, 1).pipe(map((res) => res.count));
  }

  getReportsInParallel(
    count: number,
    queryParams: ReportQueryParams,
    offset: number = 0,
    reports: Report[] = []
  ): Observable<Report[]> {
    const limit = 200;
    const maxParallelCalls = 3;
    if (count > limit) {
      // Decides how many api calls should be sent at once in parallel, upto a max number
      const numberOfCallsRequired = Math.ceil(count / limit);
      const numberOfParallelCalls = Math.min(numberOfCallsRequired, maxParallelCalls);

      return from(Array(numberOfParallelCalls).keys()).pipe(
        mergeMap((i) => this.getReportsByParams(queryParams, offset + 200 * i, limit)),
        map((response) => response.data),
        // Since we receive [Response1, Response2, Response3], we will reduce them into array of reports.
        reduce((accumulatedReports, currentReports) => [...accumulatedReports, ...currentReports]),
        mergeMap((iterationCategories) => {
          offset += 600;
          reports = [...reports, ...iterationCategories];
          const remainingCount = count - iterationCategories.length;
          if (remainingCount > 0) {
            // If we still have more data to fetch, call the same function again but with remaining count
            return this.getReportsInParallel(remainingCount, queryParams, offset, reports);
          } else {
            return of(reports);
          }
        })
      );
    } else {
      // This case will execute when the reports can be fetched in one api call only
      return this.getReportsByParams(queryParams, offset, limit).pipe(
        map((response) => [...reports, ...response.data])
      );
    }
  }

  removeTransaction(rptId: string, txnId: string): Observable<Record<string, any>> {
    const params = {
      data: {
        id: rptId,
        expense_ids: [txnId],
      },
    };
    return this.platformV1SpenderService.post('/reports/eject_expenses', params);
  }

  getFormattedReports(reports: Report | Report[]): AddToReportOptions | AddToReportOptions[] {
    const titleCasePipe = new TitleCasePipe();
    const limitCharactersPipe = new SlicePipe();
    const statePipe = new StatePipe();
    if (isArray(reports)) {
      const formattedReports = reports.map((res) =>
        this.formatReport(res, titleCasePipe, statePipe, limitCharactersPipe)
      );
      return formattedReports;
    } else {
      return this.formatReport(reports, titleCasePipe, statePipe, limitCharactersPipe);
    }
  }

  private formatReport(
    report: Report,
    titleCasePipe: TitleCasePipe,
    statePipe: StatePipe,
    limitCharactersPipe: SlicePipe
  ) {
    let optionText = report.purpose + ' (' + titleCasePipe.transform(statePipe.transform(report.state)) + ') ';
    optionText = limitCharactersPipe.transform(optionText, 0, 40);
    return { displayValue: optionText, report };
  }
}
