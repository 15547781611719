<div class="location" [formGroup]="expenseForm">
  <div class="common-input flex" [ngClass]="{'text-error': expenseForm.controls[inputId].touched && expenseForm.controls[inputId].invalid}">
    <label for="{{inputId}}">
    {{ locationField.field_name }} *
    </label>
    @if (locationField.type === 'LOCATION') {
      <app-location-autocomplete
        [form]="expenseForm"
        [inputName]="inputId"
        [inputId]="inputId"
        [placeholder]="locationField.placeholder">
      </app-location-autocomplete>
    }
  </div>
  @if (expenseForm.controls[inputId].touched && expenseForm.controls[inputId].invalid) {
    <span class="text-error"> Please enter {{ locationField.field_name }}.</span>
  }
</div>
