<div class="cost-center" [formGroup]="expenseForm">
  <div class="common-input" [ngClass]="{'text-error': expenseForm.controls.costCenter.touched && expenseForm.controls.costCenter.invalid}">
    <label for="cost-center">{{ costCenterField.field_name }}
      @if (costCenterField.is_mandatory) {
        <span>*</span>
      }
    </label>
    <div class="value">
      <app-autocomplete
        formControlName="costCenter"
        inputId="costCenter"
        fieldName="name"
        type="text"
        [options]="costCenters"
        [placeholder]="costCenterField.placeholder"
        [isDropdown]="true">
      </app-autocomplete>
    </div>

  </div>
  @if (expenseForm.controls.costCenter.touched && expenseForm.controls.costCenter.invalid) {
    <span class="text-error"> Please select a {{ costCenterField.field_name }}.</span>
  }
</div>
