<div class="category" [formGroup]="expenseForm">
  <div class="common-input" [ngClass]="{'text-error': expenseForm.controls.category.touched && expenseForm.controls.category.invalid}">
    <label for="category">Category
      @if (categoryField.is_mandatory) {
        <span>*</span>
      }
    </label>
    <div class="value">
      <app-autocomplete
        formControlName="category"
        inputId="category"
        fieldName="display_name"
        type="text"
        [options]="categories"
        placeholder="Search Category"
        [isDropdown]="true">
      </app-autocomplete>
    </div>

  </div>
  @if (expenseForm.controls.category.touched && expenseForm.controls.category.invalid) {
    <span class="text-error"> Please select a category.</span>
  }
</div>
