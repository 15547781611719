@for (dependentFieldWithControl of dependentFieldsWithControl; track $index) {
  <div class="dependent-fields" [formGroup]="dependentFieldWithControl.control">
    <div class="common-input" [ngClass]="{'text-error': dependentFieldWithControl.control.touched && dependentFieldWithControl.control.invalid}">
      <label for="dependent-field-{{ $index }}">
        {{ dependentFieldWithControl.field | titlecase }}
        @if (dependentFieldWithControl.mandatory) {
          <span>*</span>
        }
      </label>
      <div class="value">
        <p-autoComplete
          #autoComplete
          inputStyleClass="text-black font-600 w-full text-12 border-0"
          autocomplete="off"
          formControlName="value"
          id="p-dependent-field-{{ $index }}"
          inputId="dependent-field-{{ $index }}"
          type="text"
          [placeholder]="dependentFieldWithControl.placeholder | titlecase"
          [suggestions]="filteredOptions"
          [dropdown]="true"
          [completeOnFocus]="true"
          (completeMethod)="filterOptions(dependentFieldWithControl, $event)"
          (onClear)="onInputKeyUp(dependentFieldWithControl, $event, autoComplete)"
          (onSelect)="setValue($event, dependentFieldWithControl)"
          >
          <ng-template let-option pTemplate="option">
            <div
              class="flex items-center px-8-px py-8-px"
              [ngClass]="{ highlight: option === dependentFieldWithControl?.value }"
              >
              <span class="option-text whitespace-pre-wrap break-all">{{ option }}</span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    @if (dependentFieldWithControl.control.touched && dependentFieldWithControl.control.invalid) {
      <span class="text-error">Please select a valid {{ dependentFieldWithControl.field }}</span>
    }
  </div>
}

@if (isDependentFieldLoading) {
  <div class="animate-pulse pt-12-px">
    <div class="h-10 rounded bg-light-grey"></div>
  </div>
}
