import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'state',
})
export class StatePipe implements PipeTransform {
  private states: Record<string, string> = {
    DRAFT: 'incomplete',
    APPROVER_PENDING: 'reported',
    APPROVER_INQUIRY: 'sent_back',
    APPROVED: 'approved',
    PAYMENT_PENDING: 'payment_pending',
    PAYMENT_PROCESSING: 'payment_processing',
    PAID: 'paid',
    COMPLETE: 'complete',
  };

  transform(value: string): string {
    return this.states[value];
  }
}
