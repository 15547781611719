import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep, isNumber } from 'lodash';
import { AutoComplete } from 'primeng/autocomplete';
import { Currency } from '@core/models/platform/v1/common/currency.model';
import { CurrencyService } from '@core/services/platform/v1/common/currency.service';
import { RecentlyUsedItemService } from '@core/services/recently-used-item.service';
import { CurrencyObject, CurrencyOptions } from './currency.interface';
import { getNumberOfCurrencyDigits } from '@angular/common';
import { InputNumberInputEvent } from 'primeng/inputnumber';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit, OnChanges {
  @Input() currencyObj: CurrencyObject;

  @Input() currencies: Currency[];

  @Input() options?: CurrencyOptions;

  @Input() form: UntypedFormGroup;

  @Input() isCorporateCardEnabled?: boolean;

  @Input() recentlyUsedCurrencies?: string[];

  isHomeCurrency: boolean = true;

  filteredCurrencies: Currency[];

  exchangeRate: number = 1;

  homeCurrency: string;

  homeCurrencyFraction: number;

  foreignCurrencyFraction: number;

  private exchangeRateSet: boolean;

  constructor(private currencyService: CurrencyService, private recentlyUsedService: RecentlyUsedItemService) {}

  ngOnInit() {
    this.setCurrencyFormState();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentCurrencyObjValue = changes.currencyObj?.currentValue;
    const previousCurrencyObjValue = changes.currencyObj?.previousValue;

    if (currentCurrencyObjValue?.txnDt !== previousCurrencyObjValue?.txnDt && !this.exchangeRateSet) {
      this.setExchangeRate();
    }
  }

  onAutoCompleteClear(autoComplete: AutoComplete) {
    this.filteredCurrencies = this.currencies;
    autoComplete.handleDropdownClick(autoComplete);
  }

  onCurrencySelection(event: { value: Currency }, toHome: boolean) {
    if (toHome) {
      this.currencyObj.currency = event.value?.id;
      this.isHomeCurrency = this.currencyObj.currency === this.homeCurrency;
      if (!this.isHomeCurrency) {
        this.currencyObj.origCurrency = this.currencyObj.currency;
        this.currencyObj.currency = this.homeCurrency;
        this.currencyObj.origAmount = this.currencyObj.amount;
        this.currencyObj.amount = undefined;
      }
    } else {
      this.currencyObj.origCurrency = event.value?.id;
      this.isHomeCurrency = this.currencyObj.origCurrency === this.homeCurrency;

      if (this.isHomeCurrency) {
        this.currencyObj.currency = this.homeCurrency;
        this.currencyObj.amount = this.currencyObj.origAmount;
        this.currencyObj.origCurrency = null;
        this.currencyObj.origAmount = null;
      }
    }

    this.form.controls.origAmount.setValue(this.currencyObj.origAmount);
    this.form.controls.amount.setValue(this.currencyObj.amount);

    this.setExchangeRate();
    this.setCurrencyFraction();
  }

  // Setting the number of fractions for currency and orig_currency, this will be used for clamping the input amount
  setCurrencyFraction() {
    this.homeCurrencyFraction = getNumberOfCurrencyDigits(this.currencyObj.currency);
    this.foreignCurrencyFraction = getNumberOfCurrencyDigits(this.currencyObj.origCurrency);
  }

  onAmountChange(event: InputNumberInputEvent) {
    if (event.value) {
      if (!isNumber(event.value)) {
        this.form.controls.origAmount.reset();
        return;
      }
      if (this.isHomeCurrency) {
        this.currencyObj.amount = event.value;
      } else {
        this.currencyObj.origAmount = event.value;
      }
    } else {
      this.currencyObj.origAmount = undefined;
      this.currencyObj.amount = undefined;
      this.form.controls.origAmount.reset();
      this.form.controls.amount.reset();
    }
    this.updateAmount();
  }

  onBlur() {
    if (!this.currencyObj.amount) {
      this.updateAmount();
    } else {
      this.exchangeRateSet = true;
    }
  }

  onFinalAmountChange(event: InputNumberInputEvent) {
    this.currencyObj.amount = +event.value;
    if (this.currencyObj.amount && this.currencyObj.origAmount) {
      const amount = this.currencyObj.amount / this.currencyObj.origAmount;
      this.exchangeRate = amount > -1 ? parseFloat(amount.toFixed(7)) : 1;
    }
  }

  private setCurrencyFormState() {
    this.currencyService.getAll().subscribe((currencies) => {
      this.currencies = currencies;
      if (this.recentlyUsedCurrencies && this.recentlyUsedCurrencies.length > 0) {
        const sortedRecentCurrencies = this.recentlyUsedService.getFilteredRecentlyUsedItems(
          cloneDeep(currencies),
          this.recentlyUsedCurrencies,
          'currency'
        ) as Currency[];
        this.currencies = sortedRecentCurrencies.concat(currencies);
      }
      this.filteredCurrencies = this.currencies;
      let parsedCurrency: Currency | undefined;
      if (this.currencyObj.origCurrency) {
        parsedCurrency = this.filteredCurrencies.find((currency) => currency.id === this.currencyObj.origCurrency);
      } else if (this.currencyObj.currency) {
        parsedCurrency = this.filteredCurrencies.find((currency) => currency.id === this.currencyObj.currency);
      }

      if (parsedCurrency) {
        this.form.controls.currency.setValue(parsedCurrency);
      }
    });

    if (this.options && this.options.homeCurrency) {
      this.homeCurrency = this.options.homeCurrency;
      this.setCurrencyObjState();
    } else {
      this.currencyService.getHomeCurrency().subscribe((homeCurrency) => {
        this.homeCurrency = homeCurrency;
        this.setCurrencyObjState();
      });
    }
    if (this.currencyObj.txnDt) {
      this.setExchangeRate();
    }

    this.setCurrencyFraction();
  }

  private updateAmount() {
    if (!this.isHomeCurrency) {
      const amount = this.currencyObj.origAmount * this.exchangeRate;
      this.currencyObj.amount = this.currencyService.getAmountWithCurrencyFraction(amount, this.currencyObj.currency);
    }
    this.form.controls.origAmount.setValue(this.currencyObj.origAmount);
    this.form.controls.amount.setValue(this.currencyObj.amount);
  }

  private calculateExchangeRate() {
    const amount =
      this.currencyObj.amount && this.currencyObj.origAmount && this.currencyObj.amount / this.currencyObj.origAmount;
    this.exchangeRate = amount ? parseFloat(amount.toFixed(7)) : 1;
    this.exchangeRateSet = true;
  }

  private setExchangeRate() {
    if (!this.isHomeCurrency && this.currencyObj.origCurrency && this.homeCurrency) {
      this.currencyService
        .getExchangeRate(this.currencyObj.origCurrency, this.homeCurrency, this.currencyObj.txnDt)
        .subscribe((rate) => {
          this.exchangeRate = rate;
          this.updateAmount();
        });
    }
  }

  private setOriginalState() {
    // If not home currency, set orig_currency to selected one and currency to home currency
    this.currencyObj.origCurrency = this.currencyObj.currency;
    this.currencyObj.currency = this.homeCurrency;
    this.currencyObj.origAmount = this.currencyObj.amount;
    this.setExchangeRate();
  }

  private handleCurrencyAmountExtraction() {
    if (this.currencyObj.origAmount && this.currencyObj.origCurrency) {
      this.isHomeCurrency = this.currencyObj.origCurrency === this.homeCurrency;

      if (!this.isHomeCurrency) {
        this.calculateExchangeRate();
      }
    } else {
      this.isHomeCurrency = this.currencyObj.currency === this.homeCurrency;
      if (!this.isHomeCurrency) {
        this.setOriginalState();
      }
    }
  }

  private handleCurrencyExtraction() {
    const currency = this.currencyObj.currency;

    if (currency) {
      this.isHomeCurrency = currency === this.homeCurrency;

      if (this.isHomeCurrency) {
        this.currencyObj.origCurrency = undefined;
        this.currencyObj.origAmount = undefined;
      } else {
        this.setOriginalState();
      }
    } else {
      // If no currency is passed, then set currency and orig_currency to home currency, optionally if amount is passed set amount to orig_amount
      this.currencyObj.currency = this.homeCurrency;
      this.currencyObj.origCurrency = undefined;
      this.currencyObj.origAmount = this.currencyObj.amount;
    }
  }

  private setCurrencyObjState() {
    // Check if currency object is passed
    if (this.currencyObj) {
      if (this.currencyObj.currency && this.currencyObj.amount) {
        this.handleCurrencyAmountExtraction();
      } else {
        this.handleCurrencyExtraction();
      }
    } else {
      // If no currency object is sent, set defaults
      this.currencyObj = {
        currency: this.homeCurrency,
        amount: undefined,
        origCurrency: undefined,
        origAmount: undefined,
      };
    }
  }
}
