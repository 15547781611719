<div class="expense-purpose" [formGroup]="expenseForm">
  <div class="common-input purpose-field" [ngClass]="{'text-error': expenseForm.controls.purpose.touched && expenseForm.controls.purpose.invalid }">
    <label for="fyle-purpose">
      {{ purposeField.field_name }}
      @if (purposeField.is_mandatory) {
        <span>*</span>
      }
    </label>
    <div class="mt-2 w-full">
      @if (purposeField.type === 'TEXT') {
        <input
          pInputText
          autocomplete="off"
          class="border-0 text-black font-600 pl-0 w-full"
          type="text"
          formControlName="purpose"
          id="purpose"
          inputId="purpose"
          [placeholder]="purposeField.placeholder"/>
      }

      @if (purposeField.type === 'SELECT') {
        <app-autocomplete
          type="text"
          formControlName="purpose"
          inputId="purpose"
          [placeholder]="purposeField.placeholder"
          [options]="purposeField.options"
          [isDropdown]="true">
        </app-autocomplete>
      }
    </div>
  </div>
  @if (expenseForm.controls.purpose.touched && expenseForm.controls.purpose.invalid) {
    @if (purposeField.type === 'TEXT') {
      <span class="text-error">Please enter {{ purposeField.field_name }}.</span>
    }
    @if (purposeField.type === 'SELECT') {
      <span class="text-error">Please select a {{ purposeField.field_name }}.</span>
    }
  }
</div>