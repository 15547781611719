import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-critical-policy-violated-description',
  templateUrl: './critical-policy-violated-description.component.html',
  styleUrls: ['./critical-policy-violated-description.component.scss'],
})
export class CriticalPolicyViolatedDescriptionComponent implements OnInit {
  @Input() rules: string[];

  constructor() {}

  ngOnInit(): void {}
}
