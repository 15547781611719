import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/platform/v1/spender/api-response.model';
import { Employee } from '@core/models/platform/v1/spender/employee.model';
import { Observable } from 'rxjs';
import { PlatformV1SpenderService } from './spender.service';
import { map } from 'rxjs/operators';
import { ApiRequestParams } from '@core/models/platform/v1/spender/api-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private platformV1SpenderService: PlatformV1SpenderService) {}

  // Returns a list of employees based on query params
  getEmployeesByParams(queryParams: ApiRequestParams): Observable<Employee[]> {
    return this.platformV1SpenderService
      .get<ApiResponse<Employee[]>>('/employees', { params: { ...queryParams } })
      .pipe(map((response: ApiResponse<Employee[]>) => response.data));
  }

  // Searches employees based on full name or email
  getEmployeesByFullnameOrEmail(query: string): Observable<Employee[]> {
    let orCondition = `(is_enabled.eq.true,has_accepted_invite.eq.false)`;

    if (query && query.length > 0) {
      const searchCondition = this.getOrParamForQuery(query);
      orCondition += `&or=${searchCondition}`; // Add the search condition
    }

    const params: ApiRequestParams = {
      limit: 200,
      offset: 0,
      or: orCondition,
      order: 'full_name.asc,email.asc,user_id.asc',
    };

    return this.getEmployeesByParams(params);
  }

  // Constructs the OR query parameter for full name or email search
  private getOrParamForQuery(query: string): string {
    const encodedQuery = encodeURIComponent(query);
    const fullName = `full_name.ilike.%${encodedQuery}%`;
    const email = `email.ilike.%${encodedQuery}%`;
    return `(${fullName},${email})`;
  }
}
