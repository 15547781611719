@if (orgs.length === 1) {
  <div class="text-14" [textContent]="currentOrg.name"></div>
} @else {
  <p-dropdown
    class="w-40 flex"
    (onHide)="onOrgChange()"
    [ngModel]="currentOrg"
    (ngModelChange)="modelChange($event)"
    [options]="orgs"
    [optionLabel]="'name'"
    styleClass="w-full"
    panelStyleClass="w-full">
  </p-dropdown>
}