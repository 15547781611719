import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-critical-policy-violated',
  templateUrl: './critical-policy-violated.component.html',
  styleUrls: ['./critical-policy-violated.component.scss'],
})
export class CriticalPolicyViolatedComponent implements OnInit {
  @Input() isCritical: boolean;

  @Input() rules: string[];

  @Output() save: EventEmitter<void> = new EventEmitter();

  @Output() cancel: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onSave() {
    this.save.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
