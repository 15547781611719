import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';

@Component({
  selector: 'app-flight-travel-class',
  templateUrl: './flight-travel-class.component.html',
  styleUrls: ['./flight-travel-class.component.scss'],
})
export class FlightTravelClassComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() travelClassField: ExpenseField;

  @Input() inputId: string;

  constructor() {}

  ngOnInit() {}
}
