import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-upload-failed',
  templateUrl: './file-upload-failed.component.html',
  styleUrls: ['./file-upload-failed.component.scss'],
})
export class FileUploadFailedComponent implements OnInit {
  @Input() txnId: string;

  constructor(private window: Window) {}

  ngOnInit() {}

  openViewExpense() {
    const uri = `${environment.APP_URL}/main/#/my_expenses/?state=all&txnId=${this.txnId}`;
    this.window.open(uri, '_blank');
  }
}
