import { DecimalPipe, DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CustomField } from '@core/models/platform/v1/spender/expense.model';

@Pipe({
  name: 'customFieldValuePipe',
})
export class CustomFieldValuePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  /* eslint-disable complexity */
  transform(customField: CustomField): string | null {
    let displayValue: string | null = '-';

    const decimalPipe = new DecimalPipe(this.locale);
    const datePipe = new DatePipe(this.locale);
    /* eslint-disable indent */
    switch (customField.type) {
      case 'TEXT':
      case 'SELECT':
      case 'DEPENDENT_SELECT':
        displayValue = customField.value || '-';
        break;

      case 'NUMBER':
        displayValue = customField.value ? decimalPipe.transform(customField.value, '1.2-2') : '-';
        break;

      case 'BOOLEAN':
        displayValue = customField.value ? 'Yes' : 'No';
        break;

      case 'MULTI_SELECT':
      case 'USER_LIST':
        displayValue = customField.value?.length > 0 ? customField.value.join(', ') : '-';
        break;

      case 'LOCATION':
        if (customField.value) {
          if (customField.value.hasOwnProperty('display')) {
            displayValue = customField.value.display || '-';
          } else {
            displayValue = '-';
          }
        }
        break;

      case 'DATE':
        displayValue = customField ? datePipe.transform(customField.value, 'MMM dd, yyyy') : '-';
        break;

      default:
        /* eslint-disable no-console */
        console.error('unknown property type', customField.type);
    }
    return displayValue;
  }
}
