import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { CustomFields } from '@core/models/custom-field.model';
import { DependentFieldsService } from '@core/services/platform/v1/spender/dependent-fields.service';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { DependentFieldWithControl } from './dependent-field-with-control.model';

@Component({
  selector: 'app-dependent-fields',
  templateUrl: './dependent-fields.component.html',
  styleUrls: ['./dependent-fields.component.scss'],
})
export class DependentFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dependentFieldsFormArray: UntypedFormArray;

  @Input() dependentFields: CustomFields[];

  @Input() parentFieldId: number;

  @Input() parentFieldValue: string;

  //This variable tracks if user updated the value of parent field
  @Input() parentValueChanged: boolean;

  isDependentFieldLoading = false;

  dependentFieldsWithControl: DependentFieldWithControl[] = [];

  unsubscribeSubject$ = new Subject();

  filteredOptions: string[];

  constructor(private formBuilder: FormBuilder, private dependentFieldsService: DependentFieldsService) {}

  ngOnInit(): void {
    this.isDependentFieldLoading = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.parentFieldValue) {
      this.dependentFieldsFormArray?.clear();
      this.dependentFieldsWithControl = [];

      //If user edits the value of parent field, then clear all dependent field values
      if (this.parentValueChanged) {
        this.dependentFields = this.dependentFields.map((dependentField) => ({
          ...dependentField,
          value: null,
        }));
      }

      this.getDependentField(this.parentFieldId, changes?.parentFieldValue.currentValue)
        .pipe(filter((res) => !!res?.dependentField))
        .subscribe((res) => this.addDependentField(res.dependentField, res.parentFieldValue));
    }
  }

  filterOptions(dependentFieldWithControl: DependentFieldWithControl, event: AutoCompleteCompleteEvent) {
    const { id: fieldId, parentFieldId, parentFieldValue } = dependentFieldWithControl;
    if (parentFieldValue) {
      this.dependentFieldsService
        .getOptionsForDependentField({
          fieldId,
          parentFieldId,
          parentFieldValue,
          searchQuery: event.query,
        })
        .pipe(debounceTime(300))
        .subscribe((dependentFieldOptions) => {
          this.filteredOptions = dependentFieldOptions.map(
            (dependentFieldOption) => dependentFieldOption.expense_field_value
          );

          //If the selected option is not in the filtered options, then add it to the filtered options.
          const { value: dependentFieldValue } = dependentFieldWithControl;
          if (dependentFieldValue) {
            const selectedOption = this.filteredOptions.find(
              (dependentFieldOption) => dependentFieldValue === dependentFieldOption
            );

            if (!selectedOption) {
              this.filteredOptions = [dependentFieldValue, ...this.filteredOptions];
            }
          }
        });
    }
  }

  setValue(event: AutoCompleteSelectEvent, dependentFieldWithControl: DependentFieldWithControl) {
    dependentFieldWithControl.value = event.value || null;
  }

  onInputKeyUp(dependentFieldWithControl: DependentFieldWithControl, event: Event, autoComplete: AutoComplete) {
    if (autoComplete.inputEL.nativeElement.value === '') {
      this.filterOptions(dependentFieldWithControl, { originalEvent: event, query: '' });
      autoComplete.show();
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next(null);
    this.unsubscribeSubject$.complete();
  }

  private onDependentFieldValueChanged(
    data: Partial<{ id: number; label: string; parent_field_id: number; value: string }>
  ): void {
    const updatedFieldIndex = this.dependentFieldsFormArray.value.findIndex(
      (depField: any) => depField.label === data.label
    );
    //If this is not the last dependent field then remove all fields after this one and create new field based on this field.
    if (updatedFieldIndex !== this.dependentFieldsFormArray.length - 1) {
      this.removeDependentFieldsOfUpdatedField(updatedFieldIndex);
    }
    //Create new dependent field based on this field
    this.isDependentFieldLoading = true;
    this.getDependentField(data.id, data.value)
      .pipe(finalize(() => (this.isDependentFieldLoading = false)))
      .subscribe((res) => {
        if (res?.dependentField) {
          this.addDependentField(res.dependentField, res.parentFieldValue);
        }
      });
  }

  private addDependentField(dependentField: CustomFields, parentFieldValue: string): void {
    const dependentFieldControl = this.formBuilder.group({
      id: dependentField.id,
      label: dependentField.name,
      parent_field_id: dependentField.parent_field_id,
      value: [dependentField.value || null, (dependentField.mandatory || null) && Validators.required],
    });
    dependentFieldControl.valueChanges
      .pipe(takeUntil(this.unsubscribeSubject$), distinctUntilKeyChanged('value'), debounceTime(300))
      .subscribe((value) => {
        this.onDependentFieldValueChanged(value);
      });
    this.dependentFieldsWithControl.push({
      id: dependentField.id,
      parentFieldId: dependentField.parent_field_id,
      parentFieldValue,
      field: dependentField.name,
      mandatory: dependentField.mandatory,
      control: dependentFieldControl,
      placeholder: dependentField.placeholder,
      value: dependentField.value || null,
    });
    this.dependentFieldsFormArray.push(dependentFieldControl, { emitEvent: false });

    //If the dependent field has a value, then show the field that is dependent on this field.
    if (dependentField.value) {
      //Get the field whose parent_field_id is equal to the id of this field
      const dependentOfDependentField = this.dependentFields.find(
        (dependentOfDependentField) => dependentOfDependentField.parent_field_id === dependentField.id
      );

      //Add the new dependent field to the formgroup and UI
      if (dependentOfDependentField) {
        this.addDependentField(dependentOfDependentField, dependentField.value);
      }
    }
  }

  private getDependentField(
    parentFieldId: number,
    parentFieldValue: string
  ): Observable<{ dependentField: CustomFields; parentFieldValue: string }> {
    const dependentField = this.dependentFields.find(
      (dependentField) => dependentField.parent_field_id === parentFieldId
    );

    if (dependentField && parentFieldValue) {
      return this.dependentFieldsService
        .getOptionsForDependentField({
          fieldId: dependentField.id,
          parentFieldId,
          parentFieldValue,
        })
        .pipe(
          map((dependentFieldOptions: any[]) =>
            dependentFieldOptions?.length > 0 ? { dependentField, parentFieldValue } : null
          )
        );
    }
    return of(null);
  }

  private removeDependentFieldsOfUpdatedField(updatedFieldIndex: number) {
    //Remove all dependent field controls after the changed one
    for (let i = this.dependentFieldsFormArray.length - 1; i > updatedFieldIndex; i--) {
      this.dependentFieldsFormArray.removeAt(i);
    }

    //Set value of removed dependent fields to null
    const removedDependentFields = this.dependentFieldsWithControl.slice(updatedFieldIndex + 1);
    this.dependentFields = this.dependentFields.map((dependentField) => {
      if (removedDependentFields.find((removedDependentField) => removedDependentField.field === dependentField.name)) {
        dependentField.value = null;
      }
      return dependentField;
    });

    //Removing fields from UI
    this.dependentFieldsWithControl = this.dependentFieldsWithControl.slice(0, updatedFieldIndex + 1);
  }
}
