import { Component, Inject, OnDestroy, OnInit, Renderer2, signal } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ExtensionEventService } from 'src/app/core/services/extension-event.service';
import { TokenService } from 'src/app/core/services/token.service';
import { Tokens } from 'src/app/models/mail-details.interface';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { TrackingService } from '@core/services/tracking.service';
import { NGXLogger } from 'ngx-logger';
import { LaunchDarklyService } from '@core/services/launch-darkly.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isStorageAccessible = signal(false);

  isLoading = signal(true);

  private eventListener: () => void;

  constructor(
    private window: Window,
    private tokenService: TokenService,
    private extensionEventService: ExtensionEventService,
    private trackingService: TrackingService,
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private loggerService: NGXLogger,
    private launchDarklyService: LaunchDarklyService,
    @Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>
  ) {}

  ngOnInit() {
    this.isStorageAccessible.set(this.isLocalStorageAvailable());

    if (!this.isStorageAccessible()) {
      return;
    }

    /*
     * If the user logs out, we navigate to login page instead of reloading the entire application.
     * Since we already have the message details, making isLoading as false.
     */
    const messageDetails = this.extensionEventService.getMessageDetails();
    if (messageDetails) {
      this.isLoading.set(false);
    }
    this.eventListener = this.renderer.listen('window', 'message', this.messageFromExtLoader.bind(this));
  }

  ngOnDestroy() {
    if (this.eventListener) {
      this.eventListener();
    }
  }

  openDemoPage(event: MouseEvent) {
    event.stopPropagation();
    this.window.open('https://www.fylehq.com/schedule-demo', '_blank');
  }

  openSignin() {
    const fyleRedirectUrl = btoa(`${environment.EXTENSION_NETLIFY_URL}/assets/static/redirects.html`);
    const uri = `${environment.APP_URL}/accounts/#/signin?asset=chrome&fyle_redirect_url=${fyleRedirectUrl}`;
    this.window.open(uri, '_blank');
  }

  private verifyUserLogin() {
    const refreshToken = this.tokenService.getRefreshToken();
    const accessToken = this.tokenService.getAccessToken();
    const clusterDomain = this.tokenService.getClusterDomain();
    if (refreshToken && accessToken && clusterDomain) {
      this.goToAddExpense(clusterDomain);
    }
  }

  private isLocalStorageAvailable() {
    try {
      /* eslint-disable @typescript-eslint/no-unused-expressions */
      this.window.localStorage;
      return true;
    } catch (err) {
      return false;
    }
  }

  private messageFromExtLoader(event: MessageEvent) {
    if (!this.window.isFyleEvent(event)) {
      this.loggerService.warn('not a fyle event');
      return;
    }

    let tokens: Tokens = {
      accessToken: null,
      refreshToken: null,
      clusterDomain: null,
    };

    try {
      let messageDetails = this.extensionEventService.getMessageDetails();
      if (!messageDetails) {
        messageDetails = JSON.parse(event.data);
        // set the message only receiving from our extension.
        if (messageDetails?.extensionVersion) {
          this.extensionEventService.setMessageDetails(messageDetails);
          this.verifyUserLogin();
        }
      } else {
        tokens = JSON.parse(event.data);
      }
      this.extensionEventService.enableFyleButton();
      this.isLoading.set(false);
    } catch (err) {
      /* An external event with event.data being
       * pmrpc.{
       *   "jsonrpc":"2.0",
       *   "method":"receivePingRequest",
       *   "params":["extension::email::sent"],
       *   "id":"89FC4EB3-B5B1-4D0D-80F5-952B34121169"
       * }
       * is coming and failing here.
       * (https://sentry.io/organizations/fyle-technologies-private-limi/issues/2421725569)
       * Since we know this is not a valid fyleEvent, we are ignoring and not logging this.
       */
      if (!(event.data && event.data.indexOf('pmrpc') === 0)) {
        Sentry.captureException(new Error('Chromeext JSON parse error'), {
          extra: {
            eventData: event.data,
            eventOrigin: event.origin,
            eventSrcElementLocation: (event.target as any).location,
          },
        });
      }

      this.loggerService.error(err);
      return;
    }

    if (tokens.accessToken && tokens.refreshToken && tokens.clusterDomain) {
      this.tokenService.setRefreshToken(tokens.refreshToken);
      this.tokenService.setAccessToken(tokens.accessToken);
      this.tokenService.setClusterDomain(tokens.clusterDomain);
      this.goToAddExpense(tokens.clusterDomain);
    }
  }

  private goToAddExpense(clusterDomain: string) {
    this.trackingService.updateIdentity();
    this.rootUrl.next(clusterDomain);
    this.authService.refreshEou().subscribe((res) => {
      this.launchDarklyService.updateIdentity();
      this.router.navigate(['/add-edit-expense']);
    });
  }
}
