<div class="file-upload-failed text-center">
  <div class="file-upload-fail-heading">
    <svg-icon-sprite class="common-icon icon-flag" src="warning-fill"></svg-icon-sprite>
    <div class="file-upload-fail-header">File upload failed</div>
  </div>

  <p class="file-upload-fail-text">
    The expense was fyled but one or more of the attachment(s) could not be added. Verify the expense manually from the app.
  </p>

  @if (txnId) {
  <div class="flex items-center justify-center">
    <p-button
      type="button"
      class="w-full mx-5"
      styleClass="w-full uppercase"
      label="View In App"
      (click)="openViewExpense()"
    ></p-button>
  </div>
  }
</div>
