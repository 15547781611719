import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-policy-violated-description',
  templateUrl: './policy-violated-description.component.html',
  styleUrls: ['./policy-violated-description.component.scss'],
})
export class PolicyViolatedDescriptionComponent implements OnInit {
  @Input() rules: string[];

  constructor() {}

  ngOnInit(): void {}
}
