import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiV2Service {
  private apiV2Endpoint: string;

  constructor(@Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>, private httpClient: HttpClient) {
    this.rootUrl.subscribe((apiEndpoint) => {
      this.apiV2Endpoint = `${apiEndpoint}/v2`;
    });
  }

  get<T>(apiName: string, body: Record<string, any>): Observable<T> {
    const apiPath: string = `${this.apiV2Endpoint}${apiName}`;
    return this.httpClient.get<T>(apiPath, body);
  }
}
