<div class="location-autocomplete" [formGroup]="form">

  <p-autoComplete 
    [inputId]="inputId" 
    formControlName="{{inputName}}"
    [placeholder]="placeholder" 
    [minLength]="3"
    inputStyleClass="w-full border-0 text-black font-600"
    panelStyleClass="w-full"
    [suggestions]="predictions" 
    field="display"
    [autoHighlight]="true"
    (onBlur)="onBlur()"
    (onKeyUp)="onKeyUp($event)"
    (completeMethod)="search($event)" 
    (onSelect)="onLocationSelection($event)">

    <ng-template let-item pTemplate="item" let-idx="index">
      <span class="whitespace-pre-wrap break-all">{{ item.description }}</span>
    </ng-template>
  </p-autoComplete>

</div>