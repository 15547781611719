import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() inputId: string;

  @Input() locationField: ExpenseField;

  constructor() {}

  ngOnInit() {}
}
