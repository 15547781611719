import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';
import { TaxGroup } from '@core/models/platform/v1/spender/tax-group.model';

@Component({
  selector: 'app-tax-group',
  templateUrl: './tax-group.component.html',
  styleUrls: ['./tax-group.component.scss'],
})
export class TaxGroupComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() taxGroupField: ExpenseField;

  @Input() taxGroup: TaxGroup | null;

  @Input() taxAmount: number | null;

  @Input() taxGroups: TaxGroup[];

  @Input() taxLabel?: string;

  filteredTaxGroups: TaxGroup[];

  constructor() {}

  ngOnInit() {}
}
