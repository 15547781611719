<div  class="distance" [formGroup]="expenseForm">
  <div class="common-input flex items-center" [ngClass]="{'text-error': (expenseForm.controls.distance.touched || expenseForm.controls.distanceUnit.touched ) && (expenseForm.controls.distance.invalid || expenseForm.controls.distanceUnit.invalid )}">
    <label for="fyle-distance">
      {{ distanceField.field_name }} / {{ distanceUnitField.field_name }} *
    </label>

    <div class="value flex items-center justify-between">
      <p-inputNumber
        class="w-1/2"
        inputStyleClass="w-full border-0 shadow-none text-black font-600"
        autocomplete="off"
        id="p-distance"
        inputId="distance"
        formControlName="distance"
        placeholder="{{ distanceField.field_name }}"
        mode="decimal"
        [useGrouping]="false"
        [maxFractionDigits]="4">
      </p-inputNumber>

      <app-autocomplete
        id="p-distanceUnit"
        inputId="distanceUnit"
        formControlName="distanceUnit"
        [options]="distanceUnitField.options"
        placeholder="{{distanceUnitField.field_name}}"
        class="w-1/2">
      </app-autocomplete>
    </div>

  </div>
  @if ((expenseForm.controls.distance.touched || expenseForm.controls.distanceUnit.touched ) && (expenseForm.controls.distance.invalid || expenseForm.controls.distanceUnit.invalid )) {
    <div class="text-error">Please enter valid data.</div>
  }
</div>