import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { AuthResponse } from '../models/auth.model';
import { HttpRequestOptions } from '../models/http-client.model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { OrgPayload, Org } from '../models/org.model';
import { ExtendedAccount } from '../models/account.model';

@Injectable({
  providedIn: 'root',
})
export class OrgService {
  constructor(private apiService: ApiService, private authService: AuthService) {}

  @Cacheable()
  getCurrentOrg(): Observable<Org> {
    const queryParams: OrgPayload = {
      is_current: true,
    };
    const data: HttpRequestOptions = {
      params: {
        ...queryParams,
      },
    };
    return this.apiService.get<Org[]>('/orgs', data).pipe(map((orgs) => orgs[0]));
  }

  @Cacheable()
  getOrgs(): Observable<Org[]> {
    return this.apiService.get<Org[]>('/orgs').pipe(map((org) => org));
  }

  // Use if you already have Organisations List and Current Organistaion ID.
  filterCurrentOrg(orgs: Org[], currentOrgId: string): Org | undefined {
    const currentOrg = orgs.find((org) => org.id === currentOrgId);
    return currentOrg;
  }

  switchOrg(orgId: string): Observable<ExtendedAccount[]> {
    return this.apiService
      .post<AuthResponse>('/orgs/' + orgId + '/refresh_token')
      .pipe(switchMap((res) => this.authService.newRefreshToken(res.refresh_token)));
  }
}
