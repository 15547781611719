import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-localstorage-access-error',
  templateUrl: './localstorage-access-error.component.html',
  styleUrls: ['./localstorage-access-error.component.scss'],
})
export class LocalstorageAccessErrorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
