import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestOptions } from '@core/models/http-client.model';

@Injectable({
  providedIn: 'root',
})
export class PlatformV1CommonService {
  private apiEndpoint: string;

  constructor(@Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>, private httpClient: HttpClient) {
    this.rootUrl.subscribe((apiEndpoint) => {
      this.apiEndpoint = apiEndpoint;
    });
  }

  get<T>(apiName: string, config: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}/platform/v1/common${apiName}`;
    return this.httpClient.get<T>(apiPath, config);
  }
}
