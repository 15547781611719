<div class="expense-amount">
  <label for="fyle-amount" class="text-14" [ngClass]="{'text-error': !isAmountANumber(currencyObj.amount)}">Amount *</label>
  @if (currencyObj) {
    <app-currency
      [form]="expenseForm"
      [currencyObj]="currencyObj"
      [options]="options"
      [currencies]="currencies"
      [isCorporateCardEnabled]="isCorporateCardEnabled"
      [recentlyUsedCurrencies]="recentlyUsedCurrencies">
    </app-currency>
  }
</div>