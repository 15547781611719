import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FinalExpensePolicyState } from '@core/models/policy.model';
import { PolicyViolatedMessageService } from '@core/services/policy-violated-message.service';

@Component({
  selector: 'app-policy-violated',
  templateUrl: './policy-violated.component.html',
  styleUrls: ['./policy-violated.component.scss'],
})
export class PolicyViolatedComponent implements OnInit {
  @Input() isCritical: boolean;

  @Input() rules: string[];

  @Input() action?: FinalExpensePolicyState;

  @Input() comment?: string;

  @Output() save: EventEmitter<string> = new EventEmitter<string>();

  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

  isExpenseFlagged: boolean;

  isExpenseCapped: boolean;

  isPrimaryApproverSkipped: boolean;

  isAdditionalApprovalRequired: boolean;

  flaggedMessage: string;

  approverSkippedMessage: string;

  cappedAmountMessage: string;

  emails: string[];

  additionalApprovalMessage: string;

  constructor(private policyViolatedMessageService: PolicyViolatedMessageService) {}

  ngOnInit() {
    if (!this.comment) {
      this.comment = '';
    }
    this.setActionMessageFlags();
    this.setActionMessages();
  }

  onSave() {
    this.save.emit(this.comment);
  }

  onCancel() {
    this.cancel.emit(this.comment);
  }

  setActionMessageFlags() {
    this.isExpenseFlagged = this.action?.flag;
    this.isExpenseCapped = this.action?.amount !== null;
    this.isPrimaryApproverSkipped = this.action?.remove_employee_approver1;
    this.isAdditionalApprovalRequired = this.action?.add_approver_user_ids.length > 0;
  }

  setActionMessages() {
    if (this.isExpenseFlagged) {
      this.flaggedMessage = this.policyViolatedMessageService.getExpenseFlaggedMessage();
    }

    if (this.isPrimaryApproverSkipped) {
      this.approverSkippedMessage = this.policyViolatedMessageService.getApproverSkippedMessage();
    }

    //show this message when action has 'capped string
    if (this.isExpenseCapped) {
      this.action?.run_summary?.forEach((summary) => {
        if (summary.startsWith('expense will be capped to')) {
          this.cappedAmountMessage = this.policyViolatedMessageService.getExpenseCappedMessage(summary);
        }
      });
    }

    //show this message when action has 'approval from' string
    if (this.isAdditionalApprovalRequired) {
      this.action?.run_summary?.forEach((summary) => {
        if (summary.startsWith('expense will need approval from')) {
          const messageObj = this.policyViolatedMessageService.getAdditionalApprovalMessage(summary);
          this.additionalApprovalMessage = messageObj.additionalApprovalMessage;
          this.emails = messageObj.emails;
        }
      });
    }
  }
}
