<div class="fyle-extension login-wrapper text-dark-gray">
  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    @if (isStorageAccessible()) {
      <div class="main-container p-10-px">
        <div class="feature-container">
          <svg-icon-sprite class="icon-feature" src="list"></svg-icon-sprite>
          <div class="feature-details">
            <p class="feature-title"><strong class="header">Effortless expense tracking</strong></p>
            <ul class="feature-desc">
              <li class="list-item">Save expenses with a click.</li>
              <li class="list-item">Relevant expense data is extracted automatically.</li>
              <li class="list-item">Receipts in the emails are automatically attached to the expenses.</li>
              <li class="list-item">Pre-submission checks to adhere to company policies.</li>
            </ul>
          </div>
        </div>

        <div class="feature-container">
          <svg-icon-sprite class="icon-feature" src="folder"></svg-icon-sprite>
          <div class="feature-details">
            <p class="feature-title"><strong class="header">Effortless reporting</strong></p>
            <ul class="feature-desc">
              <li class="list-item">
                Add expense to existing
                <ul class="feature-desc-sub">
                  <li class="sub-list-item">Draft Reports</li>
                  <li class="sub-list-item">Projects</li>
                  <li class="sub-list-item">Cost Center</li>
                </ul>
              </li>
              <li class="list-item">Reconcile with corporate card transaction.</li>
            </ul>
          </div>
        </div>

        <div class="feature-container">
          <svg-icon-sprite class="icon-feature" src="exclamation-shield"></svg-icon-sprite>
          <div class="feature-details">
            <p class="feature-title"><strong class="header">Effortless compliance</strong></p>
            <ul class="feature-desc">
              <li class="list-item">Respond to policy checks without leaving Gmail.</li>
              <li class="list-item">Mark personal expenses with a single click.</li>
            </ul>
          </div>
        </div>

        <p-button class="block text-center" styleClass="uppercase" (click)="openDemoPage($event)" label="Schedule a demo"></p-button>

        <p class="log-in mb-0 text-center mt-10-px">
          Already registered? <a class="underline" (click)="openSignin()" href="javascript:void(0)">Log In!</a>
        </p>
      </div>
    } @else {
      <app-localstorage-access-error></app-localstorage-access-error>
    }
  }
</div>
