import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';
import { ExtendedOrgUser } from '@core/models/user.model';
import { ProjectService } from '@core/services/platform/v1/spender/project.service';
import { findLastIndex } from 'lodash';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { debounceTime } from 'rxjs/operators';
import { Project } from '@core/models/platform/v1/spender/project.model';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() extendedOrgUser: ExtendedOrgUser;

  @Input() projectField: ExpenseField;

  @Input() recentlyUsedProjectIds: number[];

  @Output() projects: EventEmitter<Project[]> = new EventEmitter();

  filteredProjects: Project[];

  selectedProject = '';

  selectProjectWarning = 'Please select a project';

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.checkAndUpdateProjectWarningText();
  }

  // Change the warning text to remove `a` from the project field name, if the field is not project.
  checkAndUpdateProjectWarningText() {
    if (this.projectField.field_name !== 'Project' && this.projectField.field_name !== 'project') {
      this.selectProjectWarning = `Please select ${this.projectField.field_name}`;
    }
  }

  filterProjects(event: AutoCompleteCompleteEvent) {
    const queryParams = {
      is_enabled: 'eq.true',
      display_name: `ilike."%${event.query}%"`,
    };
    this.projectService
      .getProjectsByParams(queryParams)
      .pipe(debounceTime(300))
      .subscribe((projectsResponse) => {
        const filteredProjects = this.projectService.getFormattedProjectsListByOrg(
          projectsResponse.data,
          this.recentlyUsedProjectIds || []
        ) as Project[];
        const lastRecentlyUsedItemIdx = findLastIndex(filteredProjects, { isRecentlyUsed: true });
        // `isFirstInOption` is added to the next option to apply the divider.
        if (lastRecentlyUsedItemIdx > -1) {
          filteredProjects[lastRecentlyUsedItemIdx + 1].isFirstInOption = true;
        }
        this.filteredProjects = filteredProjects;
        this.projects.emit(this.filteredProjects);
      });
  }

  onOptionSelection(event: AutoCompleteSelectEvent) {
    this.selectedProject = event.value.display_name;
  }

  onInputKeyUp(event: Event, autoComplete: AutoComplete) {
    this.selectedProject = '';
    if (autoComplete.inputEL.nativeElement.value === '') {
      this.filterProjects({ originalEvent: event, query: '' });
      autoComplete.show();
    }
  }
}
