import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/platform/v1/spender/api-response.model';
import { ExpenseField, ExpenseFieldsPayload } from '@core/models/platform/v1/spender/expense-field.model';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { HttpRequestOptions } from '../../../../models/http-client.model';
import { PlatformV1SpenderService } from './spender.service';

@Injectable({
  providedIn: 'root',
})
export class ExpenseFieldService {
  constructor(private platformV1SpenderService: PlatformV1SpenderService) {}

  @Cacheable()
  getFieldsByParams(queryParams: ExpenseFieldsPayload): Observable<ExpenseField[]> {
    const config: HttpRequestOptions = {
      params: {
        ...queryParams,
        order: 'seq.asc',
      },
    };
    return this.platformV1SpenderService.get<ApiResponse<ExpenseField[]>>('/expense_fields', config).pipe(
      map((expenseFieldsResponse) => expenseFieldsResponse.data),
      map((expenseFields) => {
        expenseFields = this.formatDefaultValue(expenseFields);
        return expenseFields;
      })
    );
  }

  @Cacheable()
  getAllCustomFields(): Observable<ExpenseField[]> {
    return this.getFieldsByParams({
      is_enabled: 'eq.true',
      is_custom: 'eq.true',
    }).pipe(shareReplay(1));
  }

  filterActiveCustomFieldsByOrgCategoryId(orgCategoryId: number): Observable<ExpenseField[]> {
    return this.getAllCustomFields().pipe(
      map((customFields) =>
        customFields.filter(
          (customField) => customField.type !== 'DEPENDENT_SELECT' && customField.category_ids.includes(orgCategoryId)
        )
      )
    );
  }

  getFieldsForViewExpensePage(fields: string[]): Observable<ExpenseField[]> {
    const columnName = `column_name.in.(${fields.join(', ')})`;
    const customQuery = 'is_custom.eq.true';
    const queryParams = {
      or: `(${columnName}, ${customQuery})`,
    };
    return this.getFieldsByParams(queryParams).pipe(shareReplay(1));
  }

  getDependentFields() {
    return this.getAllCustomFields().pipe(
      map((customFields) => customFields.filter((customField) => customField.type === 'DEPENDENT_SELECT'))
    );
  }

  getDefaultExpenseFieldValues(expenseFields: Record<string, ExpenseField>): Record<string, string | boolean> {
    const defaultValues: Record<string, string | boolean> = {};
    for (const key in expenseFields) {
      if (expenseFields.hasOwnProperty(key) && expenseFields[key].default_value) {
        defaultValues[key] = expenseFields[key].default_value;
      }
    }
    return defaultValues;
  }

  /* getBaseFieldsMap(expenseFields)
   * `expenseFields` = list returned by self.getAll()
   * method returns a mapping of column_names and their respective mapped fields
   * Object key: Column name
   * Object value: List of fields mapped to that particular column
   * Example: {
   *  boolean_column1: [{…}]
      bus_travel_class: [{…}]
      cost_center_id: [{…}]
      decimal_column1: [{…}]
      decimal_column10: [{…}]
      distance: (2) [{…}, {…}]
      ... }
   */
  getBaseFieldsMap(expenseFields: ExpenseField[]): Record<string, ExpenseField[]> {
    const expenseBaseFields = expenseFields.filter((expenseField) => !expenseField.is_custom);

    const expenseBaseFieldMap: Record<string, ExpenseField[]> = {};

    for (const expenseBaseField of expenseBaseFields) {
      let expenseBaseFieldsList: ExpenseField[] = [];

      if (expenseBaseFieldMap[expenseBaseField.column_name]) {
        expenseBaseFieldsList = expenseBaseFieldMap[expenseBaseField.column_name];
      }
      expenseBaseFieldsList.push(expenseBaseField);
      expenseBaseFieldMap[expenseBaseField.column_name] = expenseBaseFieldsList;
    }

    return expenseBaseFieldMap;
  }

  /* eslint-disable max-params-no-constructor/max-params-no-constructor, complexity */
  filterBaseFieldsByOrgCategoryId(
    allExpenseFields: ExpenseField[],
    fieldsList: string[],
    orgCategoryId: number
  ): Record<string, ExpenseField> {
    const filteredExpenseFields: Record<string, ExpenseField> = {};
    const expenseBaseFieldMap = this.getBaseFieldsMap(allExpenseFields);

    const fieldsIndependentOfCategory = ['is_billable', 'project_id', 'tax_group_id', 'category_id'];
    const defaultFields = ['purpose', 'spent_at', 'merchant', 'cost_center_id'];

    for (const field of fieldsList) {
      const configurations = expenseBaseFieldMap[field];
      let filteredField: ExpenseField = {} as ExpenseField;
      const isIndependentOfCategory = fieldsIndependentOfCategory.includes(field);
      const isDefaultField = defaultFields.includes(field);

      /* eslint-disable max-depth */
      if (configurations?.length > 0) {
        for (const configuration of configurations) {
          if (orgCategoryId && !isIndependentOfCategory) {
            if (configuration.category_ids.includes(orgCategoryId)) {
              filteredField = configuration;
              break;
            }
          } else if (isDefaultField || isIndependentOfCategory) {
            // default fields and category independent fields to be added as is
            filteredField = configuration;
            break;
          }
        }
      }

      if (filteredField && Object.keys(filteredField).length > 0) {
        filteredExpenseFields[field] = filteredField;
      }
    }

    return filteredExpenseFields;
  }

  /**
   * Billable, a base expense field with default values as "true" or "false"
   * Now since expenseFields will return default field as string, so formatting it here to boolean
   */

  private formatDefaultValue(expenseFields: ExpenseField[]): ExpenseField[] {
    return expenseFields.map((field) => {
      if (field.column_name === 'is_billable') {
        field.default_value = field.default_value === 'true' ? true : false;
      }
      return field;
    });
  }

  private transformTo(expenseBaseFieldMap: Record<string, ExpenseField[]>) {
    const publicExpenseFields: Record<string, ExpenseField[]> = {
      purpose: expenseBaseFieldMap?.purpose,
      txn_dt: expenseBaseFieldMap?.spent_at,
      vendor_id: expenseBaseFieldMap?.merchant,
      cost_center_id: expenseBaseFieldMap?.cost_center_id,
      project_id: expenseBaseFieldMap?.project_id,
      from_dt: expenseBaseFieldMap?.started_at,
      to_dt: expenseBaseFieldMap?.ended_at,
      location1: expenseBaseFieldMap['locations[0]'],
      location2: expenseBaseFieldMap['locations[1]'],
      distance: expenseBaseFieldMap?.distance,
      distance_unit: expenseBaseFieldMap?.distance_unit,
      billable: expenseBaseFieldMap?.is_billable,
      tax_group_id: expenseBaseFieldMap?.tax_group_id,
      org_category_id: expenseBaseFieldMap?.category_id,
      bus_travel_class: [],
      train_travel_class: [],
      flight_journey_travel_class: [],
      flight_return_travel_class: expenseBaseFieldMap['travel_classes[1]'] || [],
    };

    if (expenseBaseFieldMap['travel_classes[0]']) {
      for (const travel_class of expenseBaseFieldMap['travel_classes[0]']) {
        if (travel_class.seq === 1) {
          publicExpenseFields.flight_journey_travel_class.push(travel_class);
        } else if (travel_class.seq === 2) {
          publicExpenseFields.bus_travel_class.push(travel_class);
        } else {
          publicExpenseFields.train_travel_class.push(travel_class);
        }
      }
    }

    return publicExpenseFields;
  }
}
