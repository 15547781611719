<div class="spend-date">

  <app-date
    [expenseForm]="expenseForm"
    [dateField]="spendDateField"
    inputId="spendDate"
    [maxDate]="maxDate"
    fieldName="spendDate">
  </app-date>

</div>