import { getCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PolicyViolatedMessageService {
  constructor() {}

  getExpenseFlaggedMessage() {
    const flaggedMessage = 'Expense will be flagged for verification and approval';
    return flaggedMessage;
  }

  getApproverSkippedMessage() {
    const approverSkippedMessage = 'Primary approver will be skipped';
    return approverSkippedMessage;
  }

  //in new design, currency symbols are used instead of curreny code string
  //extract the curreny code string and convert to currency symbol
  getExpenseCappedMessage(actionText: string) {
    const cappedAmount = actionText.match(/capped to ([a-zA-Z]{1,3} \d+)/i)[1];
    const cappedAmountSplit = cappedAmount.split(' ');
    const currency = getCurrencySymbol(cappedAmountSplit[0], 'wide', 'en');
    const cappedAmountMessage = 'Expense will be capped to ' + currency + cappedAmountSplit[1];
    return cappedAmountMessage;
  }

  //if multiple email strings present, extract them, store them in a list and append to the display string
  getAdditionalApprovalMessage(actionText: string) {
    const emails = actionText.match(/([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    let additionalApprovalMessage;
    if (emails?.length > 0) {
      additionalApprovalMessage = 'Expense will need approval from ';
    }
    return { emails, additionalApprovalMessage };
  }
}
