import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sortBy } from 'lodash';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { Org } from '@core/models/org.model';
import { OrgService } from '@core/services/org.service';
import { LaunchDarklyService } from '@core/services/launch-darkly.service';

@Component({
  selector: 'app-switch-org',
  templateUrl: './switch-org.component.html',
  styleUrls: ['./switch-org.component.scss'],
})
export class SwitchOrgComponent implements OnInit {
  @Input() currentOrg: Org;

  orgs: Org[] = [];

  private newOrg: Org;

  constructor(
    private orgService: OrgService,
    private router: Router,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit() {
    this.orgService.getOrgs().subscribe((orgs) => {
      this.orgs = orgs;
      sortBy(this.orgs, 'name');
    });
  }

  onOrgChange() {
    if (!this.newOrg || this.newOrg.id === this.currentOrg.id) {
      return;
    }

    const previousOrgId = this.currentOrg.id;

    this.orgService.switchOrg(this.newOrg.id).subscribe((res) => {
      // clear cache
      globalCacheBusterNotifier.next();

      // @link https://stackoverflow.com/a/57157316
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.navigate(['/add-edit-expense', { prev_org_id: previousOrgId }]);

      this.launchDarklyService.updateIdentity();
    });
  }

  modelChange(event: Org) {
    this.newOrg = event;
  }
}
