import { Injectable } from '@angular/core';
import { Category } from '@core/models/platform/v1/spender/category.model';
import { Observable } from 'rxjs';
import { RecentlyUsedItem } from '../models/recently-user-item.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RecentlyUsedItemService {
  constructor(private apiService: ApiService) {}

  filterListItems(itemList: Category[], query: string, listType: string): Category[] {
    const filteredList = itemList.filter((item) => {
      if (item.sub_category && listType === 'allCategories') {
        return item.name.toLowerCase().indexOf(query) > -1 || item.sub_category.toLowerCase().indexOf(query) > -1;
      } else if (item.sub_category && (listType === 'mileageCategories' || listType === 'perDiemCategories')) {
        return item.sub_category.toLowerCase().indexOf(query) > -1;
      } else {
        return item.name.toLowerCase().indexOf(query) > -1;
      }
    });

    return filteredList;
  }

  getRecentlyUsedItemsByIds(allItemsList: Category[], recentItems: number[], listType: string): Record<string, any>[] {
    const recentlyUsedItems = allItemsList
      .filter((item) => recentItems.includes(item.id))
      .map((item) => {
        item.isRecentlyUsed = true;
        return item;
      });

    return this.sortItems(recentlyUsedItems, listType);
  }

  // Retains the order of recently used items
  getFilteredRecentlyUsedItems(
    allItemsList: Record<string, any>[],
    recentItems: string[] | number[],
    listType: string
  ): Record<string, any>[] {
    const recentlyUsedItems: Record<string, any>[] = [];
    // Get all ids from the items list
    const allIds = allItemsList.map((res) => res.id);
    // Add the recent item if present in the list
    for (const recentItem of recentItems) {
      const index = allIds.indexOf(recentItem);
      if (index > -1) {
        const filteredItem = allItemsList[index];
        filteredItem.isRecentlyUsed = true;
        recentlyUsedItems.push(filteredItem);
      }
    }
    return recentlyUsedItems;
  }

  sortItems(itemList: Record<string, any>[], listType: string): Record<string, any>[] {
    let sortedList: Record<string, any>[] = [];
    if (listType === 'allCategories') {
      sortedList = this.sortByNameAndSubCategory(itemList as Category[]);
    } else if (listType === 'mileageCategories' || listType === 'perDiemCategories') {
      sortedList = this.sortBySubCategory(itemList as Category[]);
    } else if (listType === 'projects' || listType === 'costCenters' || listType === 'taxGroups') {
      sortedList = this.sortByName(itemList);
    }

    sortedList = sortedList.map((item) => {
      delete item.firstInGroup;
      return item;
    });

    if (sortedList.length > 0) {
      sortedList[0].firstInGroup = true;
    }

    return sortedList;
  }

  sortByName(list: Record<string, any>[]): Record<string, any>[] {
    list.sort((a, b) => {
      const valueA = a.name.toLowerCase();
      const valueB = b.name.toLowerCase();
      if (valueA < valueB) {
        //sort string ascending
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0; //default return value (no sorting)
    });
    return list;
  }

  sortBySubCategory(list: Category[]): Category[] {
    list.sort((a, b) => {
      const subCategoryA = a.sub_category.toLowerCase();
      const subCategoryB = b.sub_category.toLowerCase();

      if (subCategoryA < subCategoryB) {
        return -1;
      } else if (subCategoryA > subCategoryB) {
        return 1;
      }
      return 0; //default return value (no sorting)
    });

    return list;
  }

  sortByNameAndSubCategory(list: Category[] = []): Category[] {
    list.sort((categoryItemA, categoryItemB) => {
      const categoryA = categoryItemA.display_name.toLowerCase();
      const categoryB = categoryItemB.display_name.toLowerCase();

      if (categoryA < categoryB) {
        //sort string ascending
        return -1;
      } else if (categoryA > categoryB) {
        return 1;
      }
      return 0; //default return value (no sorting)
    });

    return list;
  }

  getRecentlyUsed(): Observable<RecentlyUsedItem> {
    return this.apiService.get<RecentlyUsedItem>('/recently_used');
  }
}
