import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';
import { Category } from '@core/models/platform/v1/spender/category.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() categoryField: ExpenseField;

  @Input() categories: Category[];

  constructor() {}

  ngOnInit() {}
}
