import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestOptions } from '../models/http-client.model';
import { Vendor, VendorPayload } from '../models/vendor.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  private apiEndpoint: string;

  constructor(
    @Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>,
    private httpClient: HttpClient,
    private authService: AuthService,
    private loggerService: NGXLogger
  ) {
    this.rootUrl.subscribe((apiEndpoint) => {
      this.apiEndpoint = apiEndpoint;
    });
  }

  get<T>(url: string, config?: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}/vendors${url}`;
    return this.httpClient.get<T>(apiPath, config);
  }

  getList(vendorKey: string): Observable<Vendor[]> {
    const orgUserId = this.authService.getEou().ou.id;
    const queryyparams: VendorPayload = {
      q: vendorKey,
      org_user_id: orgUserId,
    };

    const data: HttpRequestOptions = {
      params: {
        ...queryyparams,
      },
    };

    return this.get<Vendor[]>('/all', data);
  }
}
