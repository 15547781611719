import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestOptions } from '../../../../models/http-client.model';

@Injectable({
  providedIn: 'root',
})
export class PlatformV1SpenderService {
  private apiEndpoint: string;

  constructor(
    @Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>,
    private httpClient: HttpClient,
    private loggerService: NGXLogger
  ) {
    this.rootUrl.subscribe((apiEndpoint) => {
      this.apiEndpoint = apiEndpoint;
    });
  }

  get<T>(apiName: string, config: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}/platform/v1/spender${apiName}`;
    return this.httpClient.get<T>(apiPath, config);
  }

  post<T>(apiName: string, data: Record<string, any>, config?: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}/platform/v1/spender${apiName}`;
    return this.httpClient.post<T>(apiPath, data, config);
  }

  getByParams<T>(apiName: string, config: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}/platform/v1/spender${apiName}`;
    return this.httpClient.get<T>(apiPath, config);
  }
}
