import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';

@Component({
  selector: 'app-spend-date',
  templateUrl: './spend-date.component.html',
  styleUrls: ['./spend-date.component.scss'],
})
export class SpendDateComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() spendDateField: ExpenseField;

  maxDate: Date = new Date();

  constructor() {}

  ngOnInit() {}
}
