<div class="fy-autocomplete">
  <p-autoComplete #autoComplete
    name="fy-autocomplete"
    autocomplete="off"
    type="{{type || 'text'}}"
    id="p-{{inputId}}"
    inputId="{{inputId}}"
    placeholder="{{placeholder}}"
    inputStyleClass="{{ inputStyleClass || 'w-full border-0 text-black font-600' }}"
    panelStyleClass="{{panelStyleClass || 'w-full'}}"
    styleClass="{{styleClass || 'w-full'}}"
    [suggestions]="filteredOptions"
    [field]="fieldName"
    [(ngModel)]="value"
    [completeOnFocus]="true"
    [forceSelection]="multiple"
    [dropdown]="isDropdown"
    [disabled]="disabled"
    [multiple]="multiple"
    (onBlur)="onBlur()"
    (completeMethod)="filterOptions($event)"
    (onSelect)="onOptionSelection($event)"
    (onUnselect)="onOptionDeSelection($event)"
    (onKeyUp)="onInputKeyUp(autoComplete)">

    <ng-template let-option pTemplate="option" let-idx="index">
      <div class="flex items-center px-8-px py-8-px" [ngClass]="{'highlight': (currentSelectedOption === '') ? (idx === 0) : ((fieldName ? option[fieldName] : option) === currentSelectedOption) }">
        @if (option.isRecentlyUsed) {
          <svg-icon-sprite class="icon-recently-used" src="clock"></svg-icon-sprite>
        }
        <span class="option-text whitespace-pre-wrap break-all" [ngClass]="{'option-width' : option.isRecentlyUsed}">{{ fieldName ? option[fieldName] : option}}</span>
      </div>
      @if (option.isFirstInOption) {
        <div class="divider"></div>
      }
    </ng-template>
  </p-autoComplete>
</div>