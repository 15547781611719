import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-breakfast-provided',
  templateUrl: './breakfast-provided.component.html',
  styleUrls: ['./breakfast-provided.component.scss'],
})
export class BreakfastProvidedComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}
}
