import { getNumberOfCurrencyDigits } from '@angular/common';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import {
  CurrencyResponse,
  Currency,
  CurrencyApiParams,
  ExchangeRateApiParams,
  ExchangeRateResponse,
} from '@core/models/platform/v1/common/currency.model';
import { HttpRequestOptions } from '@core/models/http-client.model';
import { PlatformV1CommonService } from './common.service';
import { AuthService } from '@core/services/auth.service';
import { OrgService } from '@core/services/org.service';
import { PlatformApiResponse } from '@core/models/platform/v1/platform-api-response.model';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(
    private authService: AuthService,
    private platformV1CommonService: PlatformV1CommonService,
    private orgService: OrgService
  ) {}

  @Cacheable()
  getAll(): Observable<Currency[]> {
    const currentEou = this.authService.getEou();

    const queryParams: CurrencyApiParams = {
      org_id: currentEou && currentEou.ou && currentEou.ou.org_id,
    };

    const data: HttpRequestOptions = {
      params: {
        ...queryParams,
      },
    };
    return this.platformV1CommonService.get<PlatformApiResponse<CurrencyResponse>>('/currency/list', data).pipe(
      map((res) => {
        const currencies = this.getCurrenyList(res.data);
        return currencies;
      })
    );
  }

  @Cacheable()
  getExchangeRate(fromCurrency: string, toCurrency: string, dt = new Date(), txnId?: string): Observable<number> {
    const txnDt = dayjs(dt).format('YYYY-MM-D');
    const queryParams: ExchangeRateApiParams = {
      from: fromCurrency,
      to: toCurrency,
      date: txnDt,
    };
    if (txnId) {
      queryParams.txnId = txnId;
    }
    const data: HttpRequestOptions = {
      params: {
        ...queryParams,
      },
    };
    return this.platformV1CommonService
      .get<PlatformApiResponse<ExchangeRateResponse>>('/currency/exchange_rate', data)
      .pipe(map((res) => parseFloat(res.data.exchange_rate.toFixed(7))));
  }

  getCurrenyList(currencies: CurrencyResponse): Currency[] {
    const currencyList: Currency[] = [];

    for (const currency in currencies) {
      if (currencies.hasOwnProperty(currency)) {
        const obj: Currency = {
          id: currency,
          value: currencies[currency],
        };
        currencyList.push(obj);
      }
    }
    return currencyList;
  }

  getHomeCurrency(): Observable<string> {
    return this.orgService.getCurrentOrg().pipe(map((org) => org.currency));
  }

  /*
   * This method returns a float number with dynamic number of decimal points as specified by the currency
   * For e.g.,
   * amount = 20, currencyCode = USD will return 20.00
   * amount = 20, currencyCode = OMR will return 20.000
   */
  getAmountWithCurrencyFraction(amount: number, currencyCode: string): number {
    const currencyFraction = getNumberOfCurrencyDigits(currencyCode);
    const fixedAmount = amount.toFixed(currencyFraction);

    return parseFloat(fixedAmount);
  }
}
