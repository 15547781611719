import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { OrgService } from './org.service';
import { OrgUserService } from './org-user.service';

import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import * as LDClient from 'launchdarkly-js-client-sdk';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private ldClient: LDClient.LDClient;

  constructor(
    private orgService: OrgService,
    private orgUserService: OrgUserService,
    private authService: AuthService
  ) {}

  getVariation(key: string, defaultValue: boolean): boolean {
    if (this.ldClient) {
      return this.ldClient.variation(key, defaultValue);
    }

    return defaultValue;
  }

  // https://launchdarkly.github.io/js-client-sdk/interfaces/_launchdarkly_js_client_sdk_.ldclient.html#off
  // https://launchdarkly.github.io/js-client-sdk/interfaces/_launchdarkly_js_client_sdk_.ldclient.html#close
  shutDownClient() {
    if (this.ldClient) {
      this.ldClient.off('initialized', this.onLDInitialized, this);
      this.ldClient.off('change', this.onLDChange, this);
      this.ldClient.close();

      this.ldClient = null;
    }
  }

  updateIdentity() {
    this.getCurrentUser()
      .pipe(filter((user) => !!user))
      .subscribe((user) => {
        if (this.ldClient) {
          this.changeUser(user);
        } else {
          this.initializeUser(user);
        }
      });
  }

  private changeUser(user: LDClient.LDUser) {
    this.ldClient.identify(user);
  }

  private initializeUser(user: LDClient.LDUser) {
    this.ldClient = LDClient.initialize(environment.LAUNCH_DARKLY_CLIENT_ID, user);

    this.ldClient.on('initialized', this.onLDInitialized, this);
    this.ldClient.on('change', this.onLDChange, this);
  }

  private onLDInitialized() {}

  private onLDChange() {}

  private getCurrentUser(): Observable<LDClient.LDUser> {
    const isLoggedIn = this.authService.isLoggedIn();

    if (isLoggedIn) {
      const currentEou$ = this.orgUserService.getCurrent();
      const currentOrg$ = this.orgService.getCurrentOrg();

      return forkJoin({
        currentEou$,
        currentOrg$,
      }).pipe(
        switchMap((res) => {
          const eou = res.currentEou$;
          const org = res.currentOrg$;

          // HACK ALERT - Added (org as any).created_at because created_at is typed as a date but internally is a string
          // Typescript complains when passed date to LaunchDarkly, only primitive types and arrays are accepted
          // But since created_at is a string we have to convert currentOrg to any.
          // TODO - REMOVE THIS AFTER THE ORG MODEL IS FIXED

          const user = {
            key: eou.ou.user_id,
            custom: {
              org_id: eou.ou.org_id,
              org_user_id: eou.ou.id,
              org_currency: org.currency,
              org_created_at: (org as any).created_at,
              asset: 'CHROME_EXTENSION',
            },
          };

          return of(user);
        })
      );
    } else {
      return EMPTY;
    }
  }
}
