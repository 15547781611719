<div class="custom-fields">
  @for (customField of customFields; track $index;) {
    @if (customInputForm[$index]) {
      <div [formGroup]="customInputForm[$index]">
        <div class="common-input relative" [ngClass]="{'text-error': customInputForm[$index].controls[customField.name].touched && customInputForm[$index].controls[customField.name].invalid}">
          <label for="custom-{{$index}}">{{ customField.name }}
            @if (customField.mandatory) {
              <span>*</span>
            }
          </label>
          <div class="value">
          @switch (customField.type) {
            @case ('BOOLEAN') {
              <p-checkbox
                id="p-custom-{{$index}}"
                inputId="custom-{{customField.name}}"
                class="w-full border-0 text-black font-600"
                formControlName="{{customField.name}}"
                [binary]="true">
              </p-checkbox>
            }

            @case ('SELECT') {
              <div class="custom-autocomplete">
                <app-autocomplete
                  id="p-custom-select-{{$index}}"
                  inputId="custom-{{customField.name}}"
                  formControlName="{{customField.name}}"
                  placeholder="{{ customField.placeholder || 'Select' + customField.name }}"
                  [options]="customField.options"
                  [isDropdown]="true">
                </app-autocomplete>
              </div>
            }

            @case ('TEXT') {
              <input
                pInputText
                autocomplete="off"
                type="text"
                formControlName="{{customField.name}}"
                class="w-full border-0 text-black font-600"
                id="custom-{{customField.name}}"
                placeholder="{{ (customField.placeholder | slice:0:20) || 'Enter' + customField.name }}" >
            }

            @case ('NUMBER') {
              <p-inputNumber
                autocomplete="off"
                inputStyleClass="w-full border-0 text-black font-600"
                inputId="custom-{{customField.name}}"
                formControlName="{{customField.name}}"
                [useGrouping]="false"
                placeholder="{{ (customField.placeholder | slice:0:20) || 'Enter' + customField.name }}">
              </p-inputNumber>
            }

            @case ('LOCATION') {
              <app-location-autocomplete
                [form]="customInputForm[$index]"
                inputName="{{ customField.name }}"
                inputId="custom-{{ customField.name}}"
                [location]="customField.value">
              </app-location-autocomplete>
            }

            @case ('DATE') {
              <p-calendar #calendar
                [defaultDate]="defaultDate"
                inputStyleClass="font-600 text-black border-0 shadow-none"
                formControlName="{{customField.name}}"
                dateFormat="M dd, yy"
                id="p-custom-date-{{$index}}"
                inputId="custom-{{customField.name}}"
                placeholder="{{ (customField.placeholder | slice:0:20) || 'Select' + customField.name }}">
              </p-calendar>
              <svg-icon-sprite class="icon-feature" src="calendar" (click)="openCalendar(calendar)"></svg-icon-sprite>
            }

            @case ('MULTI_SELECT') {
              <div class="autocomplete-container">
                <app-autocomplete
                  id="p-custom-select-{{$index}}"
                  inputId="custom-{{customField.name}}"
                  formControlName="{{customField.name}}"
                  placeholder="{{ customField.placeholder || 'Select' + customField.name }}"
                  [options]="customField.options"
                  [multiple]="true"
                  [isDropdown]="true">
                </app-autocomplete>
              </div>
            }

            @case ('USER_LIST') {
              <div class="autocomplete-container">
                <p-autoComplete #autoComplete
                  autocomplete="off"
                  formControlName="{{ customField.name }}"
                  type="text"
                  styleClass="w-full"
                  inputStyleClass="w-full text-black font-600"
                  panelStyleClass="w-full"
                  id="p-custom-userlist-{{$index}}"
                  inputId="custom-{{customField.name}}"
                  placeholder="{{ (customField.placeholder | slice:0:20) || 'Select' + customField.name }}"
                  [autoHighlight]="true"
                  [completeOnFocus]="true"
                  [suggestions]="employees"
                  (onKeyUp)="onInputKeyUp($event, customField, autoComplete, $index)"
                  (onSelect)="onSelect(customField, autoComplete)"
                  (completeMethod)="searchEmployees($event, customField, $index)"
                  [multiple]="true"
                  [dropdown]="true">
                  <ng-template let-option pTemplate="option" let-idx="$index">
                    @if (idx === 0 && userListQuery[customField.name]?.length > 0) {
                      <div class="add-user-mail flex">
                        <svg-icon-sprite class="common-icon add-circle flex mr-10-px" src="plus-square"></svg-icon-sprite>
                        <span class="whitespace-pre-wrap break-all">
                          Add "<strong>{{ option }}</strong>"
                        </span>
                        <div class="divider"></div>
                      </div>
                    } @else {
                      <div class="flex items-center">
                        <span class="whitespace-pre-wrap break-all">{{ option }}</span>
                      </div>
                    }
                  </ng-template>
                </p-autoComplete>
              </div>
            }
          }
          </div>
        </div>
        @if (customInputForm[$index].controls[customField.name].touched && customInputForm[$index].controls[customField.name].invalid) {
          <span class="text-error">Please enter {{ customField.name }}</span>
        }
      </div>
    }
  }
</div>