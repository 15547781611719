/* eslint-disable no-console */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { PaymentMode } from 'src/app/add-edit-expense/add-edit-expense.interface';
import { AlertMessageType } from '../alert-message/alert-message.model';

@Component({
  selector: 'app-payment-mode',
  templateUrl: './payment-mode.component.html',
  styleUrls: ['./payment-mode.component.scss'],
})
export class PaymentModeComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() accountMappings: PaymentMode[];

  @Input() isOutstandingBalanceAvailable: boolean;

  @Input() invalidPaymentMode: boolean;

  alertMessageType: typeof AlertMessageType = AlertMessageType;

  filteredPaymentModes: PaymentMode[];

  selectedPaymentMode = '';

  constructor() {}

  ngOnInit() {}

  filterPaymentModes(event: AutoCompleteCompleteEvent) {
    console.log('filterPaymentModes', event);
    this.filteredPaymentModes = this.accountMappings.filter((option) =>
      option.name.toLowerCase().includes(event.query.toLowerCase())
    );
  }

  onOptionSelection(event: AutoCompleteSelectEvent) {
    this.selectedPaymentMode = event.value.name;
  }

  onInputKeyUp(event: Event, autoComplete: AutoComplete) {
    this.selectedPaymentMode = '';
    if (autoComplete.inputEL.nativeElement.value === '') {
      autoComplete.show();
    }
  }
}
