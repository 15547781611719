<ng-container>
  <div class="pending-details">
    <div class="pending-details-header">Invite Not Accepted</div>
    <div class="px-12-px">
        <div class="pending-details-message">
            You have been invited to {{currentOrgName}} organization, please check your previous emails and accept the invite or resend invite.
        </div>
    </div>
    <div class="fyle-btn-block justify-around">
      <p-button type="button" label="Cancel" class="btn-cancel" (click)="onCancel()"></p-button>
      <p-button type="submit" label="{{ showLoaderOnButton ? 'Sending' : 'Resend Invite' }}" class="btn-save" (click)="resendInvite()" [loading]="showLoaderOnButton"></p-button>
    </div>
  </div>
</ng-container>

