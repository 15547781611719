import { Component, Input, OnInit } from '@angular/core';
import { AlertMessageType } from './alert-message.model';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent implements OnInit {
  @Input() type: AlertMessageType;

  @Input() message: string;

  AlertMessageType: typeof AlertMessageType = AlertMessageType;

  constructor() {}

  ngOnInit() {}
}
