<div class="vendor" [formGroup]="expenseForm">
  <div class="common-input" [ngClass]="{'text-error': expenseForm.controls.vendor.touched && expenseForm.controls.vendor.invalid}">
    <label for="fyle-vendor">{{ vendorField.field_name }}
      @if (vendorField.is_mandatory) {
        <span>*</span>
      }
    </label>
    <div class="value">

      @if (vendorField.options.length === 0) {
        <p-autoComplete
          #autoComplete
          inputStyleClass="text-black font-600 w-full text-12 border-0"
          autocomplete="off"
          formControlName="vendor"
          id="p-vendor"
          inputId="vendor"
          field="display_name"
          type="text"
          [autoHighlight]="true"
          [completeOnFocus]="true"
          [placeholder]="vendorField.placeholder"
          [suggestions]="filteredVendors"
          (completeMethod)="filterVendors($event)"
          (onKeyUp)="onInputKeyUp($event, autoComplete)">
        </p-autoComplete>
      } @else {
        <app-autocomplete
          formControlName="vendor"
          inputId="vendor"
          type="text"
          [isDropdown]="true"
          [options]="vendorField.options"
          [placeholder]="vendorField.placeholder">
        </app-autocomplete>
      }
    </div>
  </div>

  <!-- Check for vendor not entered or exceeds 250 characters -->
  @if (expenseForm.controls.vendor.touched && expenseForm.controls.vendor.invalid) {
    <div class="text-error">
      @if (expenseForm.controls.vendor.hasError('maxLength')) {
        <span>{{ vendorField.field_name }} cannot be greater than 250 characters.</span>
      } @else {
        <span>Please select a {{ vendorField.field_name }}</span>
      }
    </div>
  }
</div>