import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private jwtHelpter: JwtHelperService,
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if calling api server, and not requesting for access token

    if (this.isTokenMandatory(request.url)) {
      return this.getAccessToken().pipe(
        switchMap((accessToken) => {
          if (!accessToken) {
            return this.handleError({ status: 401, error: 'Unauthorized' } as HttpErrorResponse);
          }
          return this.executeHttpRequest(request, next, accessToken);
        })
      );
    } else {
      request = request.clone({ url: request.url });
      return next.handle(request);
    }
  }

  private getUrlwithoutQueryParam(urlString: string) {
    return urlString.split('?')[0];
  }

  private executeHttpRequest(request: HttpRequest<any>, next: HttpHandler, accessToken: string) {
    request = request.clone({
      url: request.url,
      setHeaders: {
        'X-Page-Url': this.getUrlwithoutQueryParam(window.location.href),
        'X-Source-Identifier': 'chrome_extension',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  // Certain api's do not require token in headers.
  private isTokenMandatory(url: string): boolean {
    const securedApiEndpoints = url.includes('.fylehq.com') || url.includes('.fyle.tech');
    const endpointWithoutToken = url.includes('/api/auth/') && !url.includes('/api/auth/logout');
    return securedApiEndpoints && !endpointWithoutToken;
  }

  private isTokenExpiring(accessToken: string): boolean {
    try {
      const expiryDate = dayjs(this.jwtHelpter.getTokenExpirationDate(accessToken));
      const now = dayjs();
      const differenceSeconds = expiryDate.diff(now, 'seconds');
      const maxRefreshDifferenceSeconds = 2 * 60;
      return differenceSeconds < maxRefreshDifferenceSeconds;
    } catch (err) {
      return true;
    }
  }

  private refreshAccessToken(): Observable<string | null> {
    const refreshToken = this.tokenService.getRefreshToken();

    if (refreshToken) {
      return this.authService.refreshAccessToken(refreshToken).pipe(
        catchError((error) => this.handleError(error)),
        map((res) => this.tokenService.getAccessToken())
      );
    } else {
      return of(null);
    }
  }

  /**
   * This method get current accessToken from Storage, check if this token is expiring or not.
   * If the token is expiring it will get another accessToken from API and return the new accessToken
   * If multiple API call initiated then `this.refreshTokenInProgress` will block multiple access_token call
   * Reference: https://stackoverflow.com/a/57638101
   */
  private getAccessToken(): Observable<string | null> {
    const accessToken = this.tokenService.getAccessToken();

    if (accessToken && !this.isTokenExpiring(accessToken)) {
      return of(accessToken);
    }

    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      return this.refreshAccessToken().pipe(
        switchMap((newAccessToken) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(newAccessToken);
          return of(newAccessToken);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1),
        map(() => this.tokenService.getAccessToken())
      );
    }
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.authService.clearCache();
      this.router.navigate(['/auth/login']);
    }
    return throwError(error);
  }
}
