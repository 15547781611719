import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpRequestOptions } from '../models/http-client.model';
import { LocationAutoCompletePayload, LocationAutoCompleteResponse, LocationDetail } from '../models/location.model';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private apiEndpoint: string;

  constructor(@Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>, private httpClient: HttpClient) {
    this.rootUrl.subscribe((apiEndpoint) => {
      this.apiEndpoint = apiEndpoint;
    });
  }

  get<T>(url: string, config?: HttpRequestOptions): Observable<T> {
    const apiPath = `${this.apiEndpoint}/location${url}`;
    return this.httpClient.get<T>(apiPath, config);
  }

  getAutocompletePredictions(
    text: string,
    userId: string,
    currentLocation?: string,
    types?: string
  ): Observable<LocationAutoCompleteResponse> {
    const queryParams: LocationAutoCompletePayload = {
      text,
      user_id: userId,
      location: currentLocation,
      types,
    };

    const params: HttpRequestOptions = {
      params: {
        ...queryParams,
      },
    };

    return this.get<LocationAutoCompleteResponse>('/autocomplete', params);
  }

  /**
   * This method gets details from /location/geocode and
   * adds the param displayName to locationDetails.display
   */
  getGeocode(placeId: string, displayName: string): Observable<LocationDetail> {
    return this.get<LocationDetail>('/geocode/' + placeId).pipe(
      map((locationDetails) => {
        // locationDetails does not contain the `display` key
        if (displayName) {
          locationDetails.display = displayName;
        }
        return locationDetails;
      })
    );
  }
}
