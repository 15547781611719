<div class="password-overlay">
  <div class="password-info-block">
    <div class="password-block le">
      @if (encryptedFiles.length === 1) {
        <p class="password-info my-4">Looks like the attachment is password protected. Please enter the password.</p>
      } @else if (encryptedFiles.length > 1) {
        <p class="password-info">Looks like some of the attachments are password protected. Please enter the passwords.</p>
      }
      <form name="vendorPasswordForm"  #vendorPasswordForm="ngForm">
        @for (file of encryptedFiles; track file) {
          <div class="password-input-block">
            <p class="my-4">{{file.name}}</p>
            <input
              pInputText
              autocomplete="off"
              type="password"
              name="vendorPassword"
              [(ngModel)]="file.password"
              class="password"
              required>
            @if (!file.isPasswordValid && vendorPasswordForm.touched) {
              <p class="validation-info-text">Please enter a valid password.</p>
            }
          </div>
        }
        <button
          pButton
          type="submit"
          (click)="onCheckDecryption()"
          [disabled]="!vendorPasswordForm.valid"
          class="uppercase"
          [label]="'Use Password'">
        </button>
      </form>
    </div>
  </div>
</div>