import { Injectable } from '@angular/core';
import { DependentFieldValuesApiParams } from '@core/models/platform/v1/spender/dependent-field-values-api-params.model';
import { ApiResponse } from '@core/models/platform/v1/spender/api-response.model';
import { DependentFieldValue } from '@core/models/platform/v1/spender/dependent-field-value.model';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PlatformV1SpenderService } from './spender.service';
import { Cacheable } from 'ts-cacheable';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';
import { ExpenseFieldService } from './expense-field.service';
import { DependentFieldValueMethodParams } from '@core/models/platform/v1/spender/dependent-field-value-method-params.model';
import { CustomField } from '@core/models/platform/v1/spender/expense.model';

@Injectable({
  providedIn: 'root',
})
export class DependentFieldsService {
  constructor(
    private platformV1SpenderService: PlatformV1SpenderService,
    private expenseFieldService: ExpenseFieldService
  ) {}

  //Returns the possible options for a dependent field based on its parent field id and value
  @Cacheable()
  getOptionsForDependentField(
    config: DependentFieldValueMethodParams,
    fullMatch = false
  ): Observable<DependentFieldValue[]> {
    const { fieldId, parentFieldId, parentFieldValue, searchQuery } = config;
    const data: DependentFieldValuesApiParams = {
      params: {
        offset: 0,
        limit: 20,
        is_enabled: 'eq.true',
        parent_expense_field_id: `eq.${parentFieldId}`,
        parent_expense_field_value: `eq."${parentFieldValue}"`,
        expense_field_id: `eq.${fieldId}`,
        order: 'expense_field_value.asc',
      },
    };

    if (searchQuery?.length) {
      data.params.expense_field_value = fullMatch ? `ilike."${searchQuery}"` : `ilike."%${searchQuery}%"`;
    }

    return this.platformV1SpenderService
      .get<ApiResponse<DependentFieldValue[]>>('/dependent_expense_field_values', data)
      .pipe(map((apiResponse) => apiResponse.data));
  }

  //This method returns array of dependent field values based on id of base field
  getDependentFieldValuesForBaseField(customFields: CustomField[], parentFieldId: number): Observable<CustomField[]> {
    return this.getDependentFieldsForBaseField(parentFieldId).pipe(
      map((dependentExpenseFields) =>
        dependentExpenseFields.reduce((dependentCustomFields, dependentExpenseField) => {
          const dependentFieldValue = customFields.find(
            (customField) => customField.name === dependentExpenseField.field_name
          );
          if (dependentFieldValue) {
            dependentFieldValue.value = dependentFieldValue.value || '-';
            return [...dependentCustomFields, dependentFieldValue];
          }
          return dependentCustomFields;
        }, [])
      ),
      shareReplay(1)
    );
  }

  //This method returns array of dependent fields based on id of base field - Project, Cost center, etc.
  getDependentFieldsForBaseField(parentFieldId: number): Observable<ExpenseField[]> {
    return this.expenseFieldService.getDependentFields().pipe(
      map((expenseFields) => {
        const dependentExpenseFields = [];
        while (parentFieldId) {
          const nextDependentField = expenseFields.find(
            (expenseField) => expenseField.parent_field_id === parentFieldId
          );
          if (nextDependentField) {
            dependentExpenseFields.push(nextDependentField);
          }
          parentFieldId = nextDependentField?.id;
        }
        return dependentExpenseFields;
      })
    );
  }
}
