import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LocationDetail, Prediction } from 'src/app/core/models/location.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocationService } from 'src/app/core/services/location.service';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';

@Component({
  selector: 'app-location-autocomplete',
  templateUrl: './location-autocomplete.component.html',
  styleUrls: ['./location-autocomplete.component.scss'],
})
export class LocationAutocompleteComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  @Input() location: LocationDetail;

  @Input() inputId: string;

  @Input() inputName: string;

  @Input() placeholder: string;

  @Output() selectedLocation: EventEmitter<LocationDetail> = new EventEmitter<LocationDetail>();

  predictions: Prediction[] = [];

  private currentLocation: string;

  private enteredValue: string;

  constructor(private locationService: LocationService, private authService: AuthService, private window: Window) {}

  ngOnInit() {
    this.setCurrentLocation();

    if (!this.placeholder) {
      this.placeholder = 'Search for Place (min 3 chars)';
    }
  }

  onKeyUp(event: KeyboardEvent) {
    const code = event.key;

    // prevent submit on enter
    if (code === 'Enter') {
      // TODO: Should we consider first choice as selected location here??
      event.preventDefault();
    }
    // If the user entered location is not part of api response, we pass whatever the user entered as location
    if (this.enteredValue) {
      this.location = {
        display: this.enteredValue,
      } as LocationDetail;
    }
  }

  onBlur() {
    this.selectedLocation.emit(this.location);
  }

  search(event: AutoCompleteCompleteEvent) {
    const currentEou = this.authService.getEou();
    this.enteredValue = event.query;

    this.locationService
      .getAutocompletePredictions(event.query, currentEou.us.id, this.currentLocation, '(cities)')
      .subscribe((res) => {
        this.predictions = res.predictions;
      });
  }

  onLocationSelection(event: AutoCompleteSelectEvent) {
    this.location = {
      display: event.value.description,
    } as LocationDetail;
    this.form.controls[this.inputName].patchValue(this.location);

    if (event.value.place_id) {
      this.locationService.getGeocode(event.value.place_id, event.value.description).subscribe((res) => {
        this.location = res;
        this.form.controls[this.inputName].patchValue(this.location);
      });
    }
  }

  private setCurrentLocation() {
    // TODO: Ask the user for permission, if location permission is not granted
    if (this.window.navigator.geolocation) {
      this.window.navigator.geolocation.getCurrentPosition((position) => {
        this.currentLocation = `${position.coords.latitude},${position.coords.longitude}`;
      });
    }
  }
}
