<div class="add-to-report" [formGroup]="expenseForm">
  <div class="common-input" [ngClass]="{'text-error' : expenseForm.controls.report.touched && expenseForm.controls.report.invalid}">
    <label for="add-to-report">Add to report</label>
    <div class="value">
      <app-autocomplete
        formControlName="report"
        inputId="report"
        fieldName="displayValue"
        [options]="formattedReports"
        placeholder="Select Report"
        [isDropdown]="true">
      </app-autocomplete>
    </div>
  </div>
</div>
@if (expenseForm.controls.report.touched && expenseForm.controls.report.invalid) {
  <div class="text-error"> Please select valid report</div>
}