import { Injectable } from '@angular/core';
import { ApiV2Response } from '@core/models/http-client.model';
import { ExtendedOrgUser, SpenderEmployee } from '@core/models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiV2Service } from './api-v2.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { DataTransformService } from './data-transform.service';

// TODO: Add type here
@Injectable({
  providedIn: 'root',
})
export class OrgUserService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private apiV2Service: ApiV2Service,
    private dataTransformService: DataTransformService
  ) {}

  getCurrent(): Observable<ExtendedOrgUser> {
    return this.apiService
      .get('/eous/current')
      .pipe(map((eou) => this.dataTransformService.unflatten(eou))) as Observable<ExtendedOrgUser>;
  }

  getEou(id: string) {
    return this.apiService.get('/eous/' + id).pipe(map((data) => this.dataTransformService.unflatten(data as any)));
  }

  getEmployeesByParams(params: Record<string, any>): Observable<SpenderEmployee[]> {
    return this.apiV2Service.get<ApiV2Response<SpenderEmployee[]>>('/spender_employees', { params }).pipe(
      map((res) => res.data),
      map((res) => {
        const flattenedResult: SpenderEmployee[] = [];
        for (const item of res) {
          const flattenedObj = this.dataTransformService.unflatten(item);
          flattenedResult.push(flattenedObj);
        }
        return flattenedResult;
      })
    );
  }

  getEmployeesByFullnameOrEmail(query: string): Observable<SpenderEmployee[]> {
    // Org Id is anyways enforced by API so not possible to get cross-org users with this.
    const currentEou = this.authService.getEou();

    const params: Record<string, any> = {
      ou_org_id: 'eq.' + currentEou.ou.org_id,
      order: 'us_full_name.asc,us_email.asc,ou_id',
      limit: 10,
    };

    const orQueries: string[] = [];
    if (query && query.length > 0) {
      orQueries.push(this.getOrParamForQuery(query));
    }
    // Construct final or/and query
    if (orQueries.length === 1) {
      params.or = orQueries[0];
    } else if (orQueries.length > 1) {
      params.and = '(or' + orQueries.join(',or') + ')';
    }

    return this.getEmployeesByParams(params);
  }

  private getOrParamForQuery(query: string): string {
    const usFullname = 'us_full_name.ilike.*' + query + '*';
    const usEmail = 'us_email.ilike.*' + query + '*';
    return '(' + usFullname + ',' + usEmail + ')';
  }
}
