import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/platform/v1/spender/api-response.model';
import { Category } from '@core/models/platform/v1/spender/category.model';
import {
  AllowedCategoriesForSelectedProject,
  Project,
  ProjectQueryParams,
} from '@core/models/platform/v1/spender/project.model';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecentlyUsedItemService } from '../../../recently-used-item.service';
import { PlatformV1SpenderService } from './spender.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private recentlyUserItem: RecentlyUsedItemService,
    private platformV1SpenderService: PlatformV1SpenderService
  ) {}

  getProjectsByParams(
    queryParams: ProjectQueryParams,
    offset: number = 0,
    limit: number = 10,
    order: string = 'name.asc'
  ): Observable<ApiResponse<Project[]>> {
    const params = {
      ...queryParams,
      offset,
      limit,
      order,
    };

    return this.platformV1SpenderService.get<ApiResponse<Project[]>>('/projects', { params });
  }

  getProjectsCount(queryParams: ProjectQueryParams = {}): Observable<number> {
    const params = {
      ...queryParams,
      offset: 0,
      limit: 1,
    };
    return this.platformV1SpenderService
      .get<ApiResponse<Project[]>>('/projects', { params })
      .pipe(map((projectsResponse) => projectsResponse.count));
  }

  filterById(projectId: number, projects: Project[]): Project | undefined {
    const matchingProject = projects.find((project) => project.id === projectId);
    return matchingProject;
  }

  /**
   * Process formatted extended projects from API v2, for project selector/dropdown field.
   * Also includes recently used.
   */
  getFormattedProjectsListByOrg(projects: Project[], recentProjectIds: number[]): Project[] {
    const sortedProjectsList = this.recentlyUserItem.sortItems(cloneDeep(projects), 'projects') as Project[];

    const sortedRecentProjects = this.recentlyUserItem.getFilteredRecentlyUsedItems(
      cloneDeep(projects),
      recentProjectIds,
      'projects'
    ) as Project[];

    // Create final list for dropdown use
    const finalProjectsList = [...sortedRecentProjects, ...sortedProjectsList];

    return finalProjectsList;
  }

  /**
   * @param projectId project id of a selected project
   * @param projectsList list of the project for an expense page
   * @param activeCategoryList list of active categories for an expense page
   * @param selectedOrgCategoryId org category id of selected category.
   * @returns allowedCategoriesForSelectedProject which consists of new category list associated with selected project
   *
   * It filters out the org category list associated with the selected project and checks whether if the pre selected category is associated with the selected project.
   * If true it keeps the selected org category else removes of that selected category.
   */
  getAllowedOrgCategoryIds(
    projectId: number,
    projectsList: Project[],
    activeCategoryList: Category[],
    selectedOrgCategoryId: number,
    isProjectCategoryRestrictionsEnabled: boolean
  ): AllowedCategoriesForSelectedProject {
    let project: Project | undefined;
    const allowedCategoriesForSelectedProject: AllowedCategoriesForSelectedProject = {
      categoryList: [],
      categoryIds: [],
      selectedOrgCategoryId,
      project: {
        id: null,
        name: null,
      } as Project,
    };

    if (projectId) {
      project = this.filterById(projectId, projectsList);
    }

    if (project) {
      allowedCategoriesForSelectedProject.project.id = project.id;
      allowedCategoriesForSelectedProject.project.name = project.name;

      // In platform when category_ids is null or if restriction settings are disabled then project contains all the categories.
      if (!project.category_ids || !isProjectCategoryRestrictionsEnabled) {
        allowedCategoriesForSelectedProject.categoryList = activeCategoryList;
      } else {
        // when category_ids contains array of ids then these are the allowed categories for given project
        allowedCategoriesForSelectedProject.categoryList = activeCategoryList.filter((category) =>
          project?.category_ids.includes(category.id)
        );
      }

      allowedCategoriesForSelectedProject.categoryIds = allowedCategoriesForSelectedProject.categoryList.map(
        (category: Category) => category.id
      );

      if (selectedOrgCategoryId && !allowedCategoriesForSelectedProject.categoryIds.includes(selectedOrgCategoryId)) {
        allowedCategoriesForSelectedProject.selectedOrgCategoryId = null;
      }
    } else {
      allowedCategoriesForSelectedProject.categoryList = activeCategoryList;

      allowedCategoriesForSelectedProject.categoryIds = allowedCategoriesForSelectedProject.categoryList.map(
        (category: Category) => category.id
      );
    }

    return allowedCategoriesForSelectedProject;
  }
}
