import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';

@Component({
  selector: 'app-expense-purpose',
  templateUrl: './expense-purpose.component.html',
  styleUrls: ['./expense-purpose.component.scss'],
})
export class ExpensePurposeComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() purposeField: ExpenseField;

  constructor() {}

  ngOnInit() {}
}
