import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExpenseField } from '@core/models/platform/v1/spender/expense-field.model';
import { CostCenter } from '@core/models/platform/v1/spender/cost-center.model';

@Component({
  selector: 'app-cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.scss'],
})
export class CostCenterComponent implements OnInit {
  @Input() expenseForm: UntypedFormGroup;

  @Input() costCenterField: ExpenseField;

  @Input() costCenters: CostCenter[];

  constructor() {}

  ngOnInit() {}
}
