import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthService } from './auth.service';
import mixpanel, { Config } from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private identityEmail: string;

  constructor(
    @Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>,
    private authService: AuthService,
    private window: Window,
    private localStorageService: LocalStorageService
  ) {
    this.rootUrl.subscribe((apiEndpoint) => {
      try {
        const enableMixpanel = environment.ENABLE_MIXPANEL;
        if (enableMixpanel === 'true' && apiEndpoint) {
          const config: Partial<Config> = {
            debug: false,
            track_pageview: false,
            persistence: 'localStorage',
          };

          const useMixpanelProxy = environment.USE_MIXPANEL_PROXY;
          if (useMixpanelProxy === 'true') {
            config.api_host = apiEndpoint + '/mixpanel';
          }

          mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN, config);
        }
      } catch (e) {}
    });
  }

  // TODO: source should be added only from here
  eventTrack(action: string, properties: Record<string, string | string[] | undefined | number[]>) {
    try {
      const userProperties = this.getUserProperties();
      mixpanel?.track(action, { ...userProperties, ...properties });
    } catch (e) {}
  }

  /*** Events related to expense ***/

  // create expense event
  createExpense(properties: Record<string, string>) {
    // Temporary hack for already logged in users - we need to know their identity
    this.updateIdentity();
    this.eventTrack('Create Expense', properties);
  }

  // delete expense event
  deleteExpense(properties: Record<string, string>) {
    this.eventTrack('Delete Expense', properties);
  }

  // edit expense event
  editExpense(properties: Record<string, string>) {
    this.eventTrack('Edit Expense', properties);
  }

  // click on Delete Expense
  clickDeleteExpense(properties: Record<string, string>) {
    this.eventTrack('Click Delete Expense', properties);
  }

  /*** Old events ***/

  removeFromExistingReportEditExpense(properties: Record<string, string>) {
    this.eventTrack('Remove Expenses from existing report through edit expense', properties);
  }

  corporateCardsMatchEditExpense(properties: Record<string, string>) {
    this.eventTrack('corporate cards match edit expense', properties);
  }

  corporateCardsUnMatchEditExpense(properties: Record<string, string>) {
    this.eventTrack('corporate cards unmatch edit expense', properties);
  }

  updateIdentity() {
    if (this.identityEmail) {
      return;
    }
    const eou = this.authService.getEou();
    const email = eou?.us?.email;
    if (email && email !== this.identityEmail) {
      try {
        mixpanel?.identify(email);
      } catch (e) {}

      this.identityEmail = email;
    }
  }
  // end hack

  private getUserProperties() {
    const properties: Record<string, string> = {};
    const eou = this.localStorageService.get('user');
    if (eou) {
      try {
        const distinctId = mixpanel?.get_distinct_id();
        if (distinctId !== eou.us.email) {
          mixpanel?.identify(eou.us.email);
          mixpanel?.people.set({
            $name: eou.us.full_name,
            $email: eou.us.email,
          });
        }
      } catch (e) {}

      properties['User Name'] = eou.us.full_name;
      properties['User Org Name'] = eou.ou.org_name;
      properties['User Org ID'] = eou.ou.org_id;
    }
    return properties;
  }
}
