<ng-container>
  <div class="policy-violation">
    <div class="policy-header">Policy Violation{{rules.length > 1 ? 's' : ''}} Found</div>
    <div class="px-12-px">
      <app-policy-violated-description [rules]="rules"></app-policy-violated-description>
      <p class="policy-violations-action-text">The policy violation will trigger the following action(s):</p>
      <div class="policy-actions-list">
        @if (isExpenseFlagged) {
          <app-policy-violated-action [actionText]="flaggedMessage" [icon]="'flag-outline'"></app-policy-violated-action>
        }
        @if (isExpenseCapped) {
          <app-policy-violated-action [actionText]="cappedAmountMessage" [icon]="'plus-minus'"></app-policy-violated-action>
        }
        @if (isPrimaryApproverSkipped) {
          <app-policy-violated-action [actionText]="approverSkippedMessage" [icon]="'user-one'"></app-policy-violated-action>
        }
        @if (isAdditionalApprovalRequired) {
          <app-policy-violated-action [actionText]="additionalApprovalMessage" [emails]="emails" [icon]="'user-two'"></app-policy-violated-action>
        }
      </div>
      <form name="policyViolationForm" class="mt-24-px" novalidate>
        <div class="flex items-center comments-label">
          <label for="purpose">Please provide additional details for approval</label>
          <div>{{comment?.length || 0}}/250</div>
        </div>
        <textarea
          pInputText
          class="comments-text-area"
          placeholder="Type the reason here"
          rows="3"
          autocomplete="off"
          type="text"
          name="comment"
          [(ngModel)]="comment"
          maxlength="250"
          highlight-on-error
          autofocus
        ></textarea>
      </form>
    </div>
    <div class="fyle-btn-block justify-around">
      <p-button type="button" label="Cancel" class="btn-cancel" (click)="onCancel()"></p-button>
      <p-button type="submit" label="Continue" class="btn-save" (click)="onSave()"></p-button>
    </div>
  </div>
</ng-container>

