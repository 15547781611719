import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
@Component({
  selector: 'app-pending-details',
  templateUrl: './pending-details.component.html',
  styleUrls: ['./pending-details.component.scss'],
})
export class PendingDetailsComponent implements OnInit {
  // show loader in the button while making the api call in case user clicks on Resend Invite button.
  @Input() showLoaderOnButton: boolean;

  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentOrgName: string;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    const eou = this.authService.getEou();
    this.currentOrgName = eou.ou.org_name;
  }

  onCancel() {
    this.closeDialog.emit(false);
  }

  resendInvite() {
    this.closeDialog.emit(true);
  }
}
